
































import Vue, { PropType } from "vue";
import { Attr } from "@/store";

export default Vue.extend({
  name: "VAttrList",
  props: {
    value: {
      type: Array as PropType<Attr[]>,
      default: function() {
        return [];
      }
    },
    length: Number
  },
  methods: {
    onNameChange(index: number, name: string) {
      if (name.includes(":")) {
        this.$message.warning("属性名不能包含符号 [:] ！");
        return;
      }
      if (this.value.find(item => item.name === name)) {
        this.$message.warning("属性名不能重复！");
        return;
      }
      this.value[index].name = name;
      this.onChange(this.value);
    },
    onValuesChange(index: number, values: string[]) {
      if (values.find(value => value.includes(":"))) {
        this.$message.warning("属性值不能包含符号 [:] ！");
        return;
      }
      this.value[index].values = values;
      this.onChange(this.value);
    },
    onDelete(index: number) {
      this.value.splice(index, 1);
      this.onChange(this.value);
    },
    onAdd() {
      this.onChange([
        ...this.value,
        {
          priority: 0,
          name: "",
          values: []
        }
      ]);
    },
    onChange(value: Attr[]) {
      this.$emit("change", [...value]);
    }
  }
});
