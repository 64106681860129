import Vue from "vue";
import {
  message,
  Avatar,
  AutoComplete,
  Badge,
  Button,
  Cascader,
  // Col,
  ConfigProvider,
  DatePicker,
  Divider,
  Dropdown,
  Form,
  FormModel,
  Icon,
  Input,
  InputNumber,
  Layout,
  Menu,
  Modal,
  // Pagination,
  // Popover,
  Radio,
  // Row,
  Select,
  Spin,
  // Steps,
  Switch,
  Table,
  Tabs,
  Tooltip,
  Upload
} from "ant-design-vue";
import "@/components/antd/index.scss";

Vue.use(Avatar);
Vue.use(AutoComplete);
Vue.use(Badge);
Vue.use(Button);
Vue.use(Cascader);
// Vue.use(Col);
Vue.use(ConfigProvider);
Vue.use(DatePicker);
Vue.use(Divider);
Vue.use(Dropdown);
Vue.use(Form);
Vue.use(FormModel);
Vue.use(Icon);
Vue.use(Input);
Vue.use(InputNumber);
Vue.use(Layout);
Vue.use(Modal);
Vue.use(Menu);
// Vue.use(Pagination);
// Vue.use(Popover);
Vue.use(Radio);
// Vue.use(Row);
Vue.use(Select);
Vue.use(Spin);
// Vue.use(Steps);
Vue.use(Switch);
Vue.use(Table);
Vue.use(Tabs);
Vue.use(Tooltip);
Vue.use(Upload);

message.config({
  top: "100px",
  maxCount: 3
});

Vue.prototype.$message = message;
Vue.prototype.$confirm = Modal.confirm;
Vue.prototype.$info = Modal.info;
