var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.subOrder.status === 'confirmed')?_c('span',[_c('a',{on:{"click":_vm.onAddRefundOrder}},[_vm._v("添加售后单")]),_c('a-modal',{attrs:{"title":"添加售后单","visible":_vm.visible,"confirm-loading":_vm.lodaing},on:{"ok":_vm.onOk,"cancel":function($event){_vm.visible = false}}},[_c('a-form',{staticClass:"form",attrs:{"form":_vm.form}},[_c('a-form-item',{attrs:{"label":"退回商品数量"}},[_c('a-input-number',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'number',
            {
              initialValue: 1,
              rules: [{ required: true, message: '请输入退回商品数量！' }]
            }
          ]),expression:"[\n            'number',\n            {\n              initialValue: 1,\n              rules: [{ required: true, message: '请输入退回商品数量！' }]\n            }\n          ]"}],staticStyle:{"width":"100%"},attrs:{"min":1,"max":_vm.subOrder.number,"precision":0}})],1),_c('a-form-item',{attrs:{"label":"退款商品单价"}},[_c('a-input-number',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'amount',
            {
              initialValue: 0,
              rules: [{ required: true, message: '请输入退款商品单价！' }]
            }
          ]),expression:"[\n            'amount',\n            {\n              initialValue: 0,\n              rules: [{ required: true, message: '请输入退款商品单价！' }]\n            }\n          ]"}],staticStyle:{"width":"100%"},attrs:{"min":0,"precision":0}})],1),_c('a-form-item',{attrs:{"label":"退款总金额","help":"退款总金额等于退款商品单价乘以退回商品数量"}},[_c('a-input',{staticStyle:{"color":"inherit","background-color":"#ffffff"},attrs:{"value":_vm.number * _vm.amount,"disabled":""}})],1),_c('a-form-item',{attrs:{"label":"备注"}},[_c('a-textarea',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'remark',
            {
              rules: [
                { required: true, message: '请备注退款原因、快递信息等！' },
                { max: 600, message: '请输入最多 600 个字符！' }
              ]
            }
          ]),expression:"[\n            'remark',\n            {\n              rules: [\n                { required: true, message: '请备注退款原因、快递信息等！' },\n                { max: 600, message: '请输入最多 600 个字符！' }\n              ]\n            }\n          ]"}],staticStyle:{"width":"100%"},attrs:{"placeholder":"请备注退款原因、快递信息等","auto-size":{ minRows: 5, maxRows: 15 }}})],1)],1)],1),_c('a-divider',{attrs:{"type":"vertical"}})],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }