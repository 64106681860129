













import Vue from "vue";
import { mapState } from "vuex";
import { dispatch } from "@/store";
import zh_CN from "ant-design-vue/lib/locale-provider/zh_CN";

export default Vue.extend({
  data() {
    return {
      loading: true
    };
  },
  computed: {
    ...mapState("app", {
      appName: "name"
    }),
    zh_CN() {
      return zh_CN;
    }
  },
  methods: {
    checkSignIn() {
      dispatch
        .appRefreshToken()
        .then(res => {
          if (res)
            dispatch
              .appGetAccount()
              .then(() => {
                this.loading = false;
                dispatch.ordersExpressSupplierAll();
              })
              .catch(() => {
                this.loading = false;
                this.$router.push({
                  path: this.$paths.auth,
                  query: this.$route.query
                });
              });
        })
        .catch(() => {
          this.loading = false;
          this.$router.push({
            path: this.$paths.auth,
            query: this.$route.query
          });
        });
    }
  },
  mounted() {
    this.$router.onReady(this.checkSignIn);
  },
  watch: {
    $route(to) {
      window.scrollTo(0, 0);
      document.title = to.name ? to.name + " - " + this.appName : this.appName;
    }
  }
});
