





























































import Vue, { PropType } from "vue";
import { dispatch, SubOrder } from "@/store";
import { area } from "@/utils";

export default Vue.extend({
  name: "VSubOrderAddress",
  props: {
    subOrder: Object as PropType<SubOrder>
  },
  data() {
    return {
      form: this.$form.createForm(this),
      visible: false,
      lodaing: false,
      area
    };
  },
  methods: {
    onEditAddress() {
      this.visible = true;
      setTimeout(() => {
        const address_info = this.subOrder.address_info;
        this.form.resetFields();
        this.form.setFieldsValue({
          consignee: address_info.consignee,
          phone: address_info.phone,
          area: [
            address_info.province,
            address_info.city,
            address_info.district
          ],
          detail: address_info.detail
        });
      }, 200);
    },
    onOk() {
      this.form.validateFields((errors, values) => {
        if (!errors) {
          this.lodaing = true;
          values.province = values.area[0];
          values.city = values.area[1];
          values.district = values.area[2];
          values.area = undefined;
          values.consignee = values.consignee.trim();
          values.phone = values.phone.trim();
          values.detail = values.detail.trim();
          dispatch
            .ordersAddressPatch({
              subOrderNo: this.subOrder.sub_order_no,
              address: {
                ...this.subOrder.address_info,
                ...values
              }
            })
            .then(() => {
              this.visible = false;
              this.lodaing = false;
              this.$message.success("收货地址修改成功");
              this.$emit("change");
            })
            .catch(() => {
              this.lodaing = false;
            });
        }
      });
    }
  }
});
