import { Commit } from "vuex";
import { request, url } from "@/api";
import { RootState } from "@/store/modules";

export interface Media {
  name: string;
  type: string;
  url: string;
}

interface Context {
  commit: Commit;
  rootState: RootState;
}

const actions = {
  async upload(
    context: Context,
    {
      file,
      callback
    }: { file: File; path: string; callback: (e: ProgressEvent) => void }
  ) {
    const res: any = await request({
      method: "POST",
      url: url.uploadSign,
      data: {
        file_prefix: "product",
        file_type: file.name.split(".").pop()
      }
    });
    const formData = new FormData();
    formData.append("OSSAccessKeyId", res.access_id);
    formData.append("policy", res.policy);
    formData.append("Signature", res.signature);
    formData.append("key", res.path);
    formData.append("file", file);
    formData.append("success_action_status", "200");
    await request({
      method: "POST",
      url: res.host,
      data: formData,
      onUploadProgress: callback
    });
    return res.host + "/" + res.path;
  }
};

export default {
  namespaced: true,
  actions
};
