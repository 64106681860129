


























import Vue from "vue";
import moment from "moment";
import { dispatch } from "@/store";

export default Vue.extend({
  name: "VBlockedList",
  data() {
    return {
      moment,
      loading: false,
      form: this.$form.createForm(this),
      labelCol: { span: 5 },
      wrapperCol: { span: 14 }
    };
  },
  methods: {
    onSubmit() {
      if (this.loading) {
        return;
      }
      this.form.validateFieldsAndScroll(
        { scroll: { offsetTop: 200 } },
        (errors, values) => {
          if (!errors) {
            this.loading = true;
            dispatch
              .settingsPatchBlockedList(
                values.ids.map((id: string) => Number(id))
              )
              .then(() => {
                this.$message.success("禁止下单名单已修改");
                this.loading = false;
              })
              .catch(() => {
                this.loading = false;
              });
          }
        }
      );
    }
  },
  mounted() {
    this.loading = true;
    dispatch.settingsGetBlockedList().then(data => {
      this.form.setFieldsValue({
        ...data
      });
      this.loading = false;
    });
  }
});
