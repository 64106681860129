import Vue from "vue";
import App from "@/App.vue";
import router from "@/router";
import store from "@/store";
import moment from "moment";
import "moment/locale/zh-cn";
import "@/components";
import "@/filters";

moment.locale("zh-cn");

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");
