var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.subOrder.status === 'paid')?_c('span',[_c('a',{on:{"click":_vm.onEditAddress}},[_vm._v("修改地址")]),_c('a-modal',{attrs:{"title":"收货地址","visible":_vm.visible,"confirm-loading":_vm.lodaing},on:{"ok":_vm.onOk,"cancel":function($event){_vm.visible = false}}},[_c('a-form',{staticClass:"form",attrs:{"form":_vm.form}},[_c('a-form-item',{attrs:{"label":"收件人"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'consignee',
            {
              rules: [{ required: true, message: '请输入收件人！' }]
            }
          ]),expression:"[\n            'consignee',\n            {\n              rules: [{ required: true, message: '请输入收件人！' }]\n            }\n          ]"}],attrs:{"placeholder":"请输入收件人"}})],1),_c('a-form-item',{attrs:{"label":"联系电话"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'phone',
            {
              rules: [{ required: true, message: '请输入联系电话！' }]
            }
          ]),expression:"[\n            'phone',\n            {\n              rules: [{ required: true, message: '请输入联系电话！' }]\n            }\n          ]"}],attrs:{"placeholder":"请输入联系电话"}})],1),_c('a-form-item',{attrs:{"label":"省市区"}},[_c('a-cascader',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'area',
            {
              initialValue: [],
              rules: [{ required: true, message: '请选择省市区！' }]
            }
          ]),expression:"[\n            'area',\n            {\n              initialValue: [],\n              rules: [{ required: true, message: '请选择省市区！' }]\n            }\n          ]"}],attrs:{"options":_vm.area}})],1),_c('a-form-item',{attrs:{"label":"详细地址"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'detail',
            {
              rules: [{ required: true, message: '请输入详细地址！' }]
            }
          ]),expression:"[\n            'detail',\n            {\n              rules: [{ required: true, message: '请输入详细地址！' }]\n            }\n          ]"}],attrs:{"placeholder":"请输入详细地址"}})],1)],1)],1),_c('a-divider',{attrs:{"type":"vertical"}})],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }