import store, {
  Oauth2Channel,
  ListQuery,
  Product,
  Order,
  Express,
  ExpressSupplier,
  Address,
  RefundOrder,
  Notification,
  Coupon,
  CouponActivity,
  CouponCode,
  CouponCodeSend,
  SNCode,
  SNProducer,
  SNProduct,
  SNCodeGenerator,
  WarehouseOrder,
  WarehousePlatform,
  WarehouseProducer,
  Settings,
  WWWConfig,
  DrawActivity
} from "@/store";

// 系统

const appSetLoading = (loading: boolean) => {
  return store.dispatch("app/setLoading", loading);
};

const appGetOauth2Url = (channel: Oauth2Channel) => {
  return store.dispatch("app/getOauth2Url", channel);
};

const appOauth2SignIn = () => {
  return store.dispatch("app/oauth2SignIn");
};

const appSendSMS = (phone: string) => {
  return store.dispatch("app/sendSMS", phone);
};

const appPhoneSignIn = (data: { phone: string; code: string }) => {
  return store.dispatch("app/phoneSignIn", data);
};

const appRefreshToken = () => {
  return store.dispatch("app/refreshToken");
};

const appGetAccount = () => {
  return store.dispatch("app/getAccount");
};

const appSignOut = () => {
  return store.dispatch("app/signOut");
};

// 文件

const filesSign = () => {
  return store.dispatch("files/sign");
};

const filesUpload = (file: File, callback?: (e: ProgressEvent) => void) => {
  return store.dispatch("files/upload", {
    file,
    callback
  });
};

// 商品

const productsGetList = (query: ListQuery) => {
  return store.dispatch("products/getList", query);
};

const productsGet = (id: number) => {
  return store.dispatch("products/get", id);
};

const productsPost = (product: Product) => {
  return store.dispatch("products/post", product);
};

const productsPatch = (product: Product) => {
  return store.dispatch("products/patch", product);
};

const productsDelete = (product: Product) => {
  return store.dispatch("products/delete", product);
};

const productsCartCount = (skuIds: number[]) => {
  return store.dispatch("products/cartCount", skuIds);
};

const productsSale = (skuIds: number[]) => {
  return store.dispatch("products/sale", skuIds);
};

const productsSaleLimitDelete = (skuId: number) => {
  return store.dispatch("products/saleLimitDelete", skuId);
};

const productsGetWhiteList = (id: number) => {
  return store.dispatch("products/getWhiteList", id);
};

const productsPatchWhiteList = ({ id, ids }: { id: number; ids: number[] }) => {
  return store.dispatch("products/patchWhiteList", { id, ids });
};

const productsSync = (ids: number[]) => {
  return store.dispatch("products/sync", ids);
};

const productsGetShare = (id: number) => {
  return store.dispatch("products/getShare", id);
};

// 订单

const ordersGetList = (query: ListQuery) => {
  return store.dispatch("orders/getList", query);
};

const ordersExport = (query: ListQuery) => {
  return store.dispatch("orders/export", query);
};

const ordersImport = (file: File) => {
  return store.dispatch("orders/import", file);
};

const ordersTemplate = () => {
  return store.dispatch("orders/template");
};

const ordersGet = (orderNo: string) => {
  return store.dispatch("orders/get", orderNo);
};

const ordersPatch = (order: Order) => {
  return store.dispatch("orders/patch", order);
};

const ordersStatusPatch = (order: Order) => {
  return store.dispatch("orders/statusPatch", order);
};

const ordersAmountPatch = (order: Order) => {
  return store.dispatch("orders/amountPatch", order);
};

const ordersLimitDelete = (order: Order) => {
  return store.dispatch("orders/limitDelete", order);
};

const ordersAddressPatch = ({
  subOrderNo,
  address
}: {
  subOrderNo: string;
  address: Address;
}) => {
  return store.dispatch("orders/addressPatch", { subOrderNo, address });
};

const ordersSubOrderStatusPatch = ({
  subOrderNo,
  status
}: {
  subOrderNo: string;
  status: string;
}) => {
  return store.dispatch("orders/subOrderStatusPatch", { subOrderNo, status });
};

const ordersShippingStatusPatch = ({
  id,
  status
}: {
  id: string;
  status: string;
}) => {
  return store.dispatch("orders/shippingStatusPatch", { id, status });
};

const ordersExpressPost = (express: Express) => {
  return store.dispatch("orders/expressPost", express);
};

const ordersExpressPatch = (express: Express) => {
  return store.dispatch("orders/expressPatch", express);
};

const ordersExpressImport = (file: File) => {
  return store.dispatch("orders/expressImport", file);
};

const ordersExpressSupplier = () => {
  return store.dispatch("orders/expressSupplier");
};

const ordersExpressSupplierPost = (expressSupplier: ExpressSupplier) => {
  return store.dispatch("orders/expressSupplierPost", expressSupplier);
};

const ordersExpressSupplierDelete = (expressSupplier: ExpressSupplier) => {
  return store.dispatch("orders/expressSupplierDelete", expressSupplier);
};

const ordersExpressSupplierAll = () => {
  return store.dispatch("orders/expressSupplierAll");
};

const ordersExpressTemplate = () => {
  return store.dispatch("orders/expressTemplate");
};

const ordersRefundOrderGetList = (orderNo: string) => {
  return store.dispatch("orders/refundOrderGetList", orderNo);
};

const ordersRefundOrderPost = (refundOrder: RefundOrder) => {
  return store.dispatch("orders/refundOrderPost", refundOrder);
};

const ordersRefundOrderStatusPatch = (refundOrder: RefundOrder) => {
  return store.dispatch("orders/refundOrderStatusPatch", refundOrder);
};

const ordersSync = (order_nos: string[]) => {
  return store.dispatch("orders/sync", order_nos);
};

// 消息通知

const notificationsGetList = (query: ListQuery) => {
  return store.dispatch("notifications/getList", query);
};

const notificationsGet = (id: number) => {
  return store.dispatch("notifications/get", id);
};

const notificationsPost = (notification: Notification) => {
  return store.dispatch("notifications/post", notification);
};

const notificationsPatch = (notification: Notification) => {
  return store.dispatch("notifications/patch", notification);
};

const notificationsDelete = (notification: Notification) => {
  return store.dispatch("notifications/delete", notification);
};

// 优惠

const couponsGetList = (query: ListQuery) => {
  return store.dispatch("coupons/getList", query);
};

const couponsPost = (coupon: Coupon) => {
  return store.dispatch("coupons/post", coupon);
};

const couponsPatch = (coupon: Coupon) => {
  return store.dispatch("coupons/patch", coupon);
};

const couponsGetClaimInfo = (id: number) => {
  return store.dispatch("coupons/getClaimInfo", id);
};

const couponsPatchCount = (coupon: Coupon) => {
  return store.dispatch("coupons/patchCount", coupon);
};

const couponsGetActivityList = (query: ListQuery) => {
  return store.dispatch("coupons/getActivityList", query);
};

const couponsGetActivity = (id: number) => {
  return store.dispatch("coupons/getActivity", id);
};

const couponsPostActivity = (couponActivity: CouponActivity) => {
  return store.dispatch("coupons/postActivity", couponActivity);
};

const couponsPatchActivity = (couponActivity: CouponActivity) => {
  return store.dispatch("coupons/patchActivity", couponActivity);
};

const couponsGetActivityClaimInfo = (id: number) => {
  return store.dispatch("coupons/getActivityClaimInfo", id);
};

const couponsPatchCode = (couponCode: CouponCode) => {
  return store.dispatch("coupons/patchCode", couponCode);
};

const couponsGetCodeList = (query: ListQuery) => {
  return store.dispatch("coupons/getCodeList", query);
};

const couponsBulkCreateCode = (data: {
  coupon_activity_id: number;
  coupon_id: number;
  number: number;
}) => {
  return store.dispatch("coupons/bulkCreateCode", data);
};

const couponsGetCodeSendList = (query: ListQuery) => {
  return store.dispatch("coupons/getCodeSendList", query);
};

const couponsGetCodeSend = (id: number) => {
  return store.dispatch("coupons/getCodeSend", id);
};

const couponsPostCodeSend = (couponCodeSend: CouponCodeSend) => {
  return store.dispatch("coupons/postCodeSend", couponCodeSend);
};

const couponsSendCodeSend = (id: number) => {
  return store.dispatch("coupons/sendCodeSend", id);
};

const couponsGetUserCouponList = (query: ListQuery) => {
  return store.dispatch("coupons/getUserCouponList", query);
};

const couponsCancelUserCoupon = (id: number) => {
  return store.dispatch("coupons/cancelUserCoupon", id);
};

const couponsGetCouponSyncList = (query: ListQuery) => {
  return store.dispatch("coupons/getCouponSyncList", query);
};

const couponsSyncUserCoupon = (id: number) => {
  return store.dispatch("coupons/syncUserCoupon", id);
};

const couponsGetCouponStock = (data: {
  coupon_id: number;
  product_id: number;
}) => {
  return store.dispatch("coupons/getCouponStock", data);
};

// 选号列表

const AMNumbersGetList = (query: ListQuery) => {
  return store.dispatch("AMNumbers/getList", query);
};

const AMNumbersGetSectionList = () => {
  return store.dispatch("AMNumbers/getSectionList");
};

const AMNumbersPost = ({
  orderNos,
  id
}: {
  orderNos: string[];
  id: number;
}) => {
  return store.dispatch("AMNumbers/post", { orderNos, id });
};

const AMNumbersExport = () => {
  return store.dispatch("AMNumbers/export");
};

// SN 码

const SNCodesGetList = (query: ListQuery) => {
  return store.dispatch("SNCodes/getList", query);
};

const SNCodesGenerator = (data: SNCodeGenerator) => {
  return store.dispatch("SNCodes/generator", data);
};

const SNCodesImport = (SNCodes: SNCode[]) => {
  return store.dispatch("SNCodes/import", SNCodes);
};

const SNCodesGetProducerList = () => {
  return store.dispatch("SNCodes/getProducerList");
};

const SNCodesPostProducer = (producer: SNProducer) => {
  return store.dispatch("SNCodes/postProducer", producer);
};

const SNCodesPatchProducer = (producer: SNProducer) => {
  return store.dispatch("SNCodes/patchProducer", producer);
};

const SNCodesGetProductList = () => {
  return store.dispatch("SNCodes/getProductList");
};

const SNCodesPostProduct = (product: SNProduct) => {
  return store.dispatch("SNCodes/postProduct", product);
};

const SNCodesPatchProduct = (product: SNProduct) => {
  return store.dispatch("SNCodes/patchProduct", product);
};

// 仓库

const warehouseGetList = (query: ListQuery) => {
  return store.dispatch("warehouse/getList", query);
};

const warehouseImport = (orders: WarehouseOrder[]) => {
  return store.dispatch("warehouse/import", orders);
};

const warehouseGetPlatformList = () => {
  return store.dispatch("warehouse/getPlatformList");
};

const warehousePostPlatform = (platform: WarehousePlatform) => {
  return store.dispatch("warehouse/postPlatform", platform);
};

const warehousePatchPlatform = (product: WarehousePlatform) => {
  return store.dispatch("warehouse/patchPlatform", product);
};

const warehouseGetProducerList = () => {
  return store.dispatch("warehouse/getProducerList");
};

const warehousePostProducer = (producer: WarehouseProducer) => {
  return store.dispatch("warehouse/postProducer", producer);
};

const warehousePatchProducer = (producer: WarehouseProducer) => {
  return store.dispatch("warehouse/patchProducer", producer);
};

// 系统设置

const settingsGet = () => {
  return store.dispatch("settings/get");
};

const settingsPost = (settings: Settings) => {
  return store.dispatch("settings/post", settings);
};

const settingsGetBlockedList = () => {
  return store.dispatch("settings/getBlockedList");
};

const settingsPatchBlockedList = (ids: number[]) => {
  return store.dispatch("settings/patchBlockedList", ids);
};

const settingsGetDelayList = () => {
  return store.dispatch("settings/getDelayList");
};

const settingsPatchDelayList = ({
  duration,
  ids
}: {
  duration: number;
  ids: number[];
}) => {
  return store.dispatch("settings/patchDelayList", { duration, ids });
};

const settingsGetWwwConfig = () => {
  return store.dispatch("settings/getWwwConfig");
};

const settingsPatchWwwConfig = (list: WWWConfig[]) => {
  return store.dispatch("settings/patchWwwConfig", list);
};

const settingsGetWwwRequestList = (query: ListQuery) => {
  return store.dispatch("settings/getWwwRequestList", query);
};

const settingsGetKeyboardCustomList = (query: ListQuery) => {
  return store.dispatch("settings/getKeyboardCustomList", query);
};

// 抽奖
const drawGetList = (query: ListQuery) => {
  return store.dispatch("draw/getList", query);
};

const drawGetActivityList = (query: ListQuery) => {
  return store.dispatch("draw/getActivityList", query);
};

const drawPostActivity = (activity: DrawActivity) => {
  return store.dispatch("draw/postActivity", activity);
};

const drawPatchActivity = (activity: DrawActivity) => {
  return store.dispatch("draw/patchActivity", activity);
};

export default {
  appSetLoading,
  appGetOauth2Url,
  appOauth2SignIn,
  appSendSMS,
  appPhoneSignIn,
  appRefreshToken,
  appSignOut,
  appGetAccount,

  filesSign,
  filesUpload,

  productsGetList,
  productsGet,
  productsPost,
  productsPatch,
  productsDelete,
  productsCartCount,
  productsSale,
  productsSaleLimitDelete,
  productsGetWhiteList,
  productsPatchWhiteList,
  productsSync,
  productsGetShare,

  ordersGetList,
  ordersExport,
  ordersImport,
  ordersTemplate,
  ordersGet,
  ordersPatch,
  ordersStatusPatch,
  ordersAmountPatch,
  ordersLimitDelete,
  ordersAddressPatch,
  ordersSubOrderStatusPatch,
  ordersShippingStatusPatch,
  ordersExpressPost,
  ordersExpressPatch,
  ordersExpressImport,
  ordersExpressTemplate,
  ordersExpressSupplier,
  ordersExpressSupplierPost,
  ordersExpressSupplierDelete,
  ordersExpressSupplierAll,
  ordersRefundOrderGetList,
  ordersRefundOrderPost,
  ordersRefundOrderStatusPatch,
  ordersSync,

  notificationsGetList,
  notificationsGet,
  notificationsPost,
  notificationsPatch,
  notificationsDelete,

  couponsGetList,
  couponsPost,
  couponsPatch,
  couponsGetClaimInfo,
  couponsPatchCount,
  couponsGetActivityList,
  couponsGetActivity,
  couponsPostActivity,
  couponsPatchActivity,
  couponsGetActivityClaimInfo,
  couponsGetCodeList,
  couponsPatchCode,
  couponsBulkCreateCode,
  couponsGetCodeSendList,
  couponsGetCodeSend,
  couponsPostCodeSend,
  couponsSendCodeSend,
  couponsGetUserCouponList,
  couponsCancelUserCoupon,
  couponsGetCouponSyncList,
  couponsSyncUserCoupon,
  couponsGetCouponStock,

  AMNumbersGetList,
  AMNumbersGetSectionList,
  AMNumbersPost,
  AMNumbersExport,

  SNCodesGetList,
  SNCodesGenerator,
  SNCodesImport,
  SNCodesGetProducerList,
  SNCodesPostProducer,
  SNCodesPatchProducer,
  SNCodesGetProductList,
  SNCodesPostProduct,
  SNCodesPatchProduct,

  warehouseGetList,
  warehouseImport,
  warehouseGetPlatformList,
  warehousePostPlatform,
  warehousePatchPlatform,
  warehouseGetProducerList,
  warehousePostProducer,
  warehousePatchProducer,

  settingsGet,
  settingsPost,
  settingsGetBlockedList,
  settingsPatchBlockedList,
  settingsGetDelayList,
  settingsPatchDelayList,
  settingsGetWwwConfig,
  settingsPatchWwwConfig,
  settingsGetWwwRequestList,
  settingsGetKeyboardCustomList,

  drawGetList,
  drawGetActivityList,
  drawPostActivity,
  drawPatchActivity
};
