import { Commit } from "vuex";
import { request, url } from "@/api";
import { RootState, ListQuery, Media } from "@/store";

export interface Attr {
  name: string;
  priority: number;
  values: string[];
  id?: number;
}

interface Info {
  create_time: string;
  deliver_at: string;
  description: string;
  detail_medias: any[];
  is_top: boolean;
  is_hidden: boolean;
  hidden_id: string;
  is_use_whitelist: boolean;
  main_medias: any[];
  name: string;
  slogan: string;
  priority: number;
  qr_code_images: any[];
  sale_at: string;
  stage: string;
  status: string;
  update_time: string;
  url: string;
  id: number;
  is_allow_send_coin: boolean;
  is_virtual: boolean;
  is_coin_pay: boolean;
}

export interface Sku {
  amount: number;
  coin: number;
  extra_amount: number;
  limit: number;
  name: string;
  medias: Media[];
  options: any[];
  priority: number;
  stock: number;
  id?: number;
  has_stock?: boolean;
  is_active?: boolean;
  coupon_id?: number;
}

interface StockPolicy {
  id?: number;
  auto: boolean;
  max_stock: number;
  sku_ids?: number[];
  type: "global" | "user";
}

export interface Product {
  attrs: Attr[];
  follow_count: number;
  following: boolean;
  product: Info;
  skus: Sku[];
  stock_policies: StockPolicy[];
}

export interface ProductsState {
  list: Product[];
  query: ListQuery;
  count: number;
}

const state: ProductsState = {
  list: [],
  query: {
    ordering: "-priority",
    page_number: 1,
    page_size: 10
  },
  count: 0
};

interface Context {
  commit: Commit;
  state: ProductsState;
  rootState: RootState;
}

const actions = {
  async getList({ commit }: Context, query: ListQuery) {
    const res: any = await request({
      url: url.product,
      params: query
    });
    commit("SET_LIST", res.results);
    commit("SET_QUERY", {
      ...query,
      page_number: res.page_number,
      page_size: res.page_size
    });
    commit("SET_COUNT", res.count);
    return res;
  },
  get(context: Context, id: number) {
    return request({
      url: url.product + "/" + id
    });
  },
  post(context: Context, product: Product) {
    return request({
      method: "post",
      url: url.product,
      data: product
    });
  },
  patch(context: Context, product: Product) {
    return request({
      method: "patch",
      url: url.product + "/" + product.product.id,
      data: product
    });
  },
  delete(context: Context, product: Product) {
    return request({
      method: "delete",
      url: url.product + "/" + product.product.id
    });
  },
  cartCount(context: Context, skuIds: number[]) {
    return request({
      method: "post",
      url: url.cartCount,
      data: {
        is_force_refresh: true,
        sku_ids: skuIds
      }
    });
  },
  sale(context: Context, skuIds: number[]) {
    return request({
      method: "post",
      url: url.sale,
      data: {
        sku_ids: skuIds
      }
    });
  },
  saleLimitDelete(context: Context, skuId: number) {
    return request({
      method: "delete",
      url: url.saleLimit + "/" + skuId
    });
  },
  getWhiteList(context: Context, id: number) {
    return request({
      url: url.product + "/" + id + "/whitelist"
    });
  },
  patchWhiteList(context: Context, { id, ids }: { id: number; ids: number[] }) {
    return request({
      method: "post",
      url: url.product + "/" + id + "/whitelist",
      data: {
        ids
      }
    });
  },
  sync(context: Context, ids: number[]) {
    return request({
      method: "post",
      url: url.productSync,
      data: {
        ids
      }
    });
  },
  getShare(context: Context, id: number) {
    return request({
      url: url.productShare,
      params: {
        product_id: id,
        page_size: 100000
      }
    });
  }
};

const mutations = {
  SET_LIST: (state: ProductsState, list: Product[]) => {
    state.list = list;
  },
  SET_QUERY: (state: ProductsState, query: ListQuery) => {
    state.query = query;
  },
  SET_COUNT: (state: ProductsState, count: number) => {
    state.count = count;
  }
};

export default {
  namespaced: true,
  state,
  actions,
  mutations
};
