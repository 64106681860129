import axios, { AxiosRequestConfig } from "axios";
import { message } from "ant-design-vue";
import { auth, download } from "@/utils";
import { url } from "@/api";

let toastMessage = (msg: string) => {
  console.log(msg);
};

export default ({ headers, ...options }: AxiosRequestConfig) => {
  return new Promise((resolve, reject) => {
    headers = {
      Accept: "application/json",
      "Access-Control-Allow-Origin": "*",
      ...headers
    };

    const token = auth.getToken();
    if (token && !headers["No-Authorization"]) {
      headers["Authorization"] = "Bearer " + token;
    }

    if (options.params) {
      for (const key in options.params) {
        options.params[key] =
          options.params[key] !== undefined
            ? String(options.params[key]).trim() || undefined
            : undefined;
      }
    }

    axios({
      ...options,
      timeout: 3600 * 1000,
      headers
    })
      .then(function(response) {
        switch (response.status) {
          case 200:
          case 201:
          case 203:
          case 204:
            if (options.responseType === "blob") {
              let filename = "下载文件";
              console.log(response);
              const disposition = response.headers["content-disposition"];
              if (disposition && disposition.indexOf("attachment") !== -1) {
                const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
                const matches = filenameRegex.exec(disposition);
                if (matches !== null && matches[1])
                  filename = decodeURIComponent(
                    matches[1].replace(/['"]/g, "")
                  );
              }
              download(response.data, filename);
              resolve(true);
            } else {
              resolve(response.data || true);
            }
            break;
          default:
            reject(response);
            break;
        }
      })
      .catch(function(error) {
        if (error.response) {
          switch (error.response.status) {
            case 401:
              window.open(
                url.authRefresh +
                  "?redirect=" +
                  encodeURIComponent(window.location.href),
                "_self"
              );
              break;
          }
          const { message, error_msg } = error.response.data;
          if (message || error_msg) {
            toastMessage(message || error_msg);
          }
          reject(error.response.data);
        }
        reject(error);
      });
  });
};

toastMessage = (msg: string) => {
  const messages: any = {
    "refund amount is greater than total amount": "退款金额超过此订单可退金额",
    "greater than refund limit": "退回商品数量超过此订单可退商品数量",
    "sku not bound coupon": "虚拟商品 SKU 未绑定正确的券码 ID",
    "json: cannot unmarshal string into Go struct field SKU.skus.coupon_id of type int":
      "虚拟商品 SKU 未绑定正确的券码 ID",
    "coupon out of stock": "虚拟商品 SKU 库存数不能超过绑定的券码库存数",
    'ent: constraint failed: pq: duplicate key value violates unique constraint "product_hidden_id_key"':
      "商品隐藏 ID 重复，请重新设置",
    'ent: constraint failed: insert node to table "couponactivity": pq: duplicate key value violates unique constraint "couponactivity_custom_id_key"':
      "活动 ID 重复，请重新设置",
    'ent: constraint failed: pq: duplicate key value violates unique constraint "couponactivity_custom_id_key"':
      "活动 ID 重复，请重新设置",
    "not allow to create, status error": "生成失败！活动或券码未启用",
    "coupon activity have ended": "券码 ID 为不在有效期内的券码",
    "ent: coupon not found": "券码 ID 不存在",
    "sku limit number greater than coupon rule receive limit":
      "售卖库存大于优惠券剩余数量",
    "not generate coupon code": "券码中没有生成可售卖的码",
    "ent: coupon_activity not found": "优惠活动不存在",
    'ent: constraint failed: insert node to table "product": pq: duplicate key value violates unique constraint "product_name_cn"':
      "产品中文名已存在",
    'ent: constraint failed: insert node to table "product": pq: duplicate key value violates unique constraint "product_name_en"':
      "产品英文名已存在",
    'ent: constraint failed: insert node to table "producer": pq: duplicate key value violates unique constraint "producer_name_cn"':
      "制造商中文名已存在",
    'ent: constraint failed: insert node to table "producer": pq: duplicate key value violates unique constraint "producer_name_en"':
      "制造商英文名已存在"
  };

  msg = messages[msg] || msg;

  if (msg.includes("strconv.ParseInt")) {
    msg = msg
      .replace("strconv.ParseInt: parsing ", "")
      .replace(": invalid syntax", " 不是一个有效的数字，请重新输入");
  }

  if (message) {
    message.destroy();
    message.info(msg);
  }
};
