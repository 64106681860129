





















































import Vue from "vue";
import { deepCopy } from "@/utils";

export default Vue.extend({
  name: "VListModal",
  props: {
    name: String,
    fields: Array,
    get: Function,
    add: Function,
    ok: Function,
    okText: String,
    edit: Function,
    remove: Function
  },
  data() {
    return {
      visible: false,
      isAdd: true,
      formVisible: false,
      list: [],
      data: {} as any
    };
  },
  computed: {
    columns(): any[] {
      const columns = [...this.fields];
      if (this.edit || this.remove) {
        columns.push({
          title: "操作",
          key: "actions",
          scopedSlots: { customRender: "actions" },
          width: "100px",
          align: "center"
        });
      }
      return columns;
    },
    modelForm(): any {
      return this.$refs.modelForm;
    }
  },
  methods: {
    getList() {
      this.get &&
        this.get().then((res: any) => {
          this.list = res.results;
        });
    },
    onVisible() {
      this.visible = true;
      this.getList();
    },
    onAdd() {
      if (this.ok) {
        this.ok().then(() => {
          this.getList();
        });
        return;
      }
      this.data = {};
      this.isAdd = true;
      this.formVisible = true;
    },
    onEdit(data: any) {
      this.data = deepCopy(data);
      this.isAdd = false;
      this.formVisible = true;
    },
    onEditOk() {
      this.modelForm.validate((valid: boolean) => {
        if (valid) {
          if (this.isAdd) {
            this.add(this.data).then(() => {
              this.modelForm.resetFields();
              this.formVisible = false;
              this.getList();
            });
          } else {
            this.edit(this.data).then(() => {
              this.modelForm.resetFields();
              this.formVisible = false;
              this.getList();
            });
          }
        }
      });
    },
    onDelete(data: any) {
      this.$confirm({
        title: "确认删除此" + this.name + "？",
        centered: true,
        onOk: () => {
          this.remove(data).then(() => {
            this.$message.success(this.name + "删除成功");
            this.getList();
          });
        }
      });
    }
  }
});
