import { Commit } from "vuex";
import { dispatch, RootState } from "@/store";
import { auth } from "@/utils";
import { isTest, request, url } from "@/api";
import { Modal } from "ant-design-vue";

interface Account {
  phone?: string;
  name?: string;
  role?: string;
}

export interface AppState {
  name: string;
  isTest: boolean;
  account: Account;
}

const state: AppState = {
  name: "怒喵科技电商平台",
  isTest,
  account: {}
};

interface Context {
  commit: Commit;
  state: AppState;
  rootState: RootState;
}

export type Oauth2Channel = "google" | "qq" | "wechat" | "discord";

const actions = {
  async refreshToken() {
    const token = auth.getToken();
    const refreshToken = auth.getRefreshToken();
    if (token) {
      return true;
    } else if (refreshToken) {
      window.open(
        url.authRefresh +
          "?redirect=" +
          encodeURIComponent(window.location.href),
        "_self"
      );
      return false;
    } else {
      auth.signOut();
      throw new Error("没有 refresh token");
    }
  },
  signOut() {
    auth.signOut();
    return;
  },
  async getAccount({ commit }: Context) {
    const res: any = await request({
      url: url.authProfile
    });
    commit("SET_ACCOUNT", res);
    if (res.role !== "admin" && res.role !== "mall_admin") {
      Modal.confirm({
        title: "账户未授权",
        content: "请复制账户 ID 提供给管理员申请授权： " + res.id,
        okText: "已授权，重新登录",
        onOk() {
          auth.signOut();
          window.location.reload();
        },
        cancelText: "关闭"
      });
      throw new Error(
        "账户未授权，请联系管理员并提供账户 ID 申请授权： " + res.id
      );
    }
    return;
  }
};

const mutations = {
  SET_ACCOUNT: (state: AppState, account: Account) => {
    state.account = account;
  }
};

export default {
  namespaced: true,
  state,
  actions,
  mutations
};
