























































































import Vue from "vue";
import moment from "moment";
import { download, xlsx } from "@/utils";
import { dispatch } from "@/store";

export default Vue.extend({
  name: "VSnGenerator",
  props: {
    productList: Array,
    producerList: Array
  },
  data() {
    return {
      visible: false,
      loading: false,
      form: {
        code_type: "am",
        am_number: null,
        year: new Date().getFullYear(),
        season: "",
        batch_number: null,
        start_number: null,
        total_number: null
      },
      rules: {
        am_number: [
          {
            required: true,
            message: "请输入产品 AM 内部立项编号"
          }
        ],
        year: [
          {
            required: true,
            message: "请选择生产年份"
          }
        ],
        season: [
          {
            required: true,
            message: "请选择生产季度"
          }
        ],
        batch_number: [
          {
            required: true,
            message: "请输入生产批次编号"
          }
        ],
        start_number: [
          {
            required: true,
            message: "请输入 SN 码起始编号"
          }
        ],
        total_number: [
          {
            required: true,
            message: "请输入 SN 码生成数量"
          }
        ]
      }
    };
  },
  methods: {
    onDownload() {
      const file = xlsx([
        {
          name: "SN码导入模板",
          data: [
            ["SN码", "防伪码", "生产日期", "(表头请勿删除)"],
            [
              moment().format("YYYY") + "0000000000",
              "xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx0000",
              moment().format("YYYY0101"),
              "(删除样例数据)"
            ]
          ],
          cols: [{ wch: 18 }, { wch: 40 }, { wch: 10 }, { wch: 18 }]
        }
      ]);
      download(file, "SN码导入模板.xlsx");
    },
    onOk() {
      (this.$refs.form as any).validate((valid: boolean) => {
        if (valid) {
          const {
            code_type,
            am_number,
            year,
            season,
            batch_number,
            start_number,
            total_number
          } = this.form as any;
          const data = {
            code_type,
            am_number,
            year: year - 2000,
            season,
            batch_number,
            start_number,
            total_number,
            calc_times: Math.floor(Math.random() * 100),
            calc_number: Math.floor(Math.random() * 9000) + 1000
          };
          this.loading = true;
          dispatch
            .SNCodesGenerator(data)
            .then(res => {
              this.loading = false;
              this.$message.success("SN 码生成成功，正在下载…");
              const xlsxData = [
                ["SN码", "防伪码", "生产日期", "基础倍数", "基础增加数"]
              ];

              res.code.map((code: string) => {
                xlsxData.push([
                  code,
                  "",
                  "",
                  data.calc_times + "",
                  data.calc_number + ""
                ]);
              });

              const file = xlsx([
                {
                  name: "SN码列表",
                  data: xlsxData,
                  cols: [{ wch: 18 }, { wch: 40 }, { wch: 10 }, { wch: 18 }]
                }
              ]);

              download(
                file,
                `SN码(${moment().format("YYYY/MM/DD HH:mm:ss")}).xlsx`
              );
            })
            .catch(() => {
              this.loading = false;
              this.$message.error("SN 码生成失败");
            });
        }
      });
    },
    onCancel() {
      (this.$refs.form as any).resetFields();
      this.visible = false;
    }
  }
});
