import Vue from "vue";
import Vuex from "vuex";
import modules from "@/store/modules";
import actions from "@/store/actions";

Vue.use(Vuex);

export default new Vuex.Store({
  actions,
  modules,
  mutations: {}
});

export { default as dispatch } from "@/store/dispatch";

/* eslint-disable */
export type { RootState, ListQuery, Address } from "@/store/modules";
export type { Oauth2Channel } from "@/store/modules/app";
export type { Product, Attr, Sku } from "@/store/modules/products";
export type { Media } from "@/store/modules/files";
export type { Order, SubOrder, Express, ExpressSupplier, RefundOrder } from "@/store/modules/orders";
export type { Notification } from "@/store/modules/notifications";
export type { Coupon, CouponActivity, CouponCode, CouponCodeSend } from "@/store/modules/coupons";
export type { AMNumber } from "@/store/modules/AMNumbers";
export type { SNCode, SNProducer, SNProduct, SNCodeGenerator } from "@/store/modules/SNCodes";
export type { WarehouseOrder, WarehousePlatform, WarehouseProducer } from "@/store/modules/warehouse";
export type { SettingsState as Settings, WWWConfig } from "@/store/modules/settings";
export type { DrawActivity } from "@/store/modules/draw";