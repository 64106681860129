



















import Vue, { VueConstructor } from "vue";
import { quillEditor } from "vue-quill-editor";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";

interface V {
  $refs: {
    upload: {
      $el: HTMLElement;
    };
    editor: {
      quill: any;
    };
  };
  addRange: {
    index: number;
  };
  editor: any;
  onImageClick(): void;
}

export default (Vue as VueConstructor<Vue & V>).extend({
  name: "VEditor",
  components: {
    quillEditor
  },
  props: {
    value: String,
    placeholder: String,
    withImage: Boolean,
    modules: Object
  },
  data() {
    return {
      addRange: null
    };
  },
  computed: {
    editor() {
      return this.$refs.editor.quill;
    },
    editorOption() {
      return {
        placeholder: this.placeholder,
        modules: this.modules || {
          toolbar: [
            [{ header: [1, 2, 3, 4, 5, 6, false] }],
            ["bold", "italic", "underline"], // toggled buttons
            // ["strike", "blockquote", "code-block"],

            // [{ header: 1 }, { header: 2 }], // custom button values
            [
              { list: "ordered" },
              { list: "bullet" },
              { indent: "-1" },
              { indent: "+1" },
              { align: [] }
            ], // outdent/indent
            // [{ script: "sub" }, { script: "super" }], // superscript/subscript
            // [{ direction: "rtl" }], // text direction

            // [{ size: ["small", false, "large", "huge"] }], // custom dropdown

            // [{ font: [], { background: [] } }],
            [
              {
                background: [
                  "rgb(251, 191, 188)",
                  "rgba(254, 212, 164, 0.8)",
                  "rgba(255, 246, 122, 0.8)",
                  "rgba(183, 237, 177, 0.8)",
                  "rgba(186, 206, 253, 0.7)",
                  "rgba(205, 178, 250, 0.7)",
                  "rgba(222, 224, 227, 0.8)",
                  "rgb(247, 105, 100)",
                  "rgb(255, 165, 61)",
                  "rgb(255, 233, 40)",
                  "rgb(98, 210, 86)",
                  "rgba(78, 131, 253, 0.55)",
                  "rgba(147, 90, 246, 0.55)",
                  "rgb(187, 191, 196)"
                ]
              },
              {
                color: [
                  "rgb(251, 191, 188)",
                  "rgba(254, 212, 164, 0.8)",
                  "rgba(255, 246, 122, 0.8)",
                  "rgba(183, 237, 177, 0.8)",
                  "rgba(186, 206, 253, 0.7)",
                  "rgba(205, 178, 250, 0.7)",
                  "rgba(222, 224, 227, 0.8)",
                  "rgb(247, 105, 100)",
                  "rgb(255, 165, 61)",
                  "rgb(255, 233, 40)",
                  "rgb(98, 210, 86)",
                  "rgba(78, 131, 253, 0.55)",
                  "rgba(147, 90, 246, 0.55)",
                  "rgb(187, 191, 196)"
                ]
              }
            ], // dropdown with defaults from theme

            this.withImage ? ["link", "image", "clean"] : ["link", "clean"]
          ]
        }
      };
    }
  },
  methods: {
    onMouseEnter() {
      this.editor.enable();
    },
    onImageClick(state: boolean) {
      this.addRange = this.editor.getSelection();
      if (state) {
        const input = this.$refs.upload.$el.querySelector("input");
        if (input) {
          input.click();
        }
      }
    },
    onUploaded({ url }: { url: string }) {
      const index = this.addRange ? this.addRange.index : 0;
      this.editor.insertEmbed(index, "image", url);
    },
    onEditorChange({ html }: { html: string }) {
      this.$emit("change", html);
    }
  },
  mounted() {
    this.editor.getModule("toolbar").addHandler("image", this.onImageClick);
  },
  watch: {
    value(newUalue, oldValue) {
      if (newUalue && !oldValue) {
        this.$nextTick().then(() => {
          this.editor.enable();
        });
      }
    }
  }
});
