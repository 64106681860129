












import Vue from "vue";

export default Vue.extend({
  name: "VUserIds",
  props: {
    value: Array
  },
  data() {
    return {};
  },
  methods: {
    onChange(value: string[]) {
      const ids = value.map(i => Number(i));
      this.$emit("change", ids);
    }
  }
});
