export { default as auth } from "@/utils/auth";
import XLSX, { ColInfo } from "xlsx";

import provinces from "@/utils/provinces";
import cities from "@/utils/cities";
import areas from "@/utils/areas";

areas.forEach((area: any) => {
  const matchCity: any = cities.filter(
    (city: any) => city.code === area.cityCode
  )[0];
  if (matchCity) {
    matchCity.children = matchCity.children || [];
    matchCity.children.push({
      label: area.name,
      value: area.name
    });
  }
});

cities.forEach((city: any) => {
  const matchProvince: any = provinces.filter(
    (province: any) => province.code === city.provinceCode
  )[0];
  if (matchProvince) {
    matchProvince.children = matchProvince.children || [];
    matchProvince.children.push({
      label: city.name,
      value: city.name,
      children: city.children
    });
  }
});

export const area = provinces.map((province: any) => ({
  label: province.name,
  value: province.name,
  children: province.children
}));

export const deepCopy = (object: object) => JSON.parse(JSON.stringify(object));

export const download = (fileOrUrl: Blob | string, fileName?: string) => {
  let url = "";
  if (typeof fileOrUrl !== "string") {
    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
      window.navigator.msSaveOrOpenBlob(fileOrUrl, fileName);
    } else {
      url = URL.createObjectURL(fileOrUrl);
    }
  } else {
    url = fileOrUrl;
  }
  const a = document.createElement("a");
  a.href = url;
  a.target = "_blank";
  a.download = fileName || url.substring(url.lastIndexOf("/") + 1);
  a.click();
};

export const imagesLoader = (images: Array<string>, callback: Function) => {
  const total = images.length;
  let completed = 0;
  images.map(src => {
    const image = new Image();
    image.src = src;
    image.onload = () => {
      callback && callback(++completed / total === 1);
    };
    image.onerror = () => {
      console.error("TOOL Error imagesLoader:" + src);
      callback && callback(++completed / total === 1);
    };
  });
};

export const importAll = (requireContext: any, exclude?: Array<string>) => {
  // https://webpack.js.org/guides/dependency-management/#requirecontext
  // you do not need `import item from './items/item'`
  // it will auto require all item from items file
  return requireContext.keys().reduce((all: any, path: string) => {
    const name = path.replace(/^.*\/(.*)\.\w+$/, "$1");
    if (!(exclude && exclude.includes(name))) {
      const value = requireContext(path);
      all[name] = value.default || value;
    }
    return all;
  }, {});
};

export const xlsx = (
  sheets: { name: string; data: any[][]; cols?: ColInfo[] }[]
) => {
  const workbook = XLSX.utils.book_new();

  sheets.map(sheet => {
    const worksheet = XLSX.utils.aoa_to_sheet(sheet.data);
    worksheet["!cols"] = sheet.cols;
    XLSX.utils.book_append_sheet(workbook, worksheet, sheet.name);
  });
  const blobPart = XLSX.write(workbook, {
    type: "array",
    bookType: "xlsx"
  });
  return new Blob([blobPart], { type: "application/octet-stream" });
};
