































import Vue, { PropType } from "vue";
import { dispatch, Order } from "@/store";

export default Vue.extend({
  name: "VOrderComment",
  props: {
    order: Object as PropType<Order>
  },
  data() {
    return {
      form: this.$form.createForm(this),
      visible: false,
      lodaing: false
    };
  },
  methods: {
    onShippingChange() {
      this.visible = true;
      setTimeout(() => {
        this.form.resetFields();
        this.form.setFieldsValue({
          order: this.order,
          comment: this.order.order.comment
        });
      }, 200);
    },
    onOk() {
      this.form.validateFields((errors, values) => {
        if (!errors) {
          const order = values.order;
          this.lodaing = true;
          order.order.comment = values.comment;
          dispatch
            .ordersPatch(order)
            .then(() => {
              this.visible = false;
              this.lodaing = false;
              this.$message.success("订单备注编辑成功");
              this.$emit("change");
            })
            .catch(() => {
              this.lodaing = false;
            });
        }
      });
    }
  }
});
