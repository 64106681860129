































import Vue from "vue";
import { ShippingStatus } from "@/enum";
import { dispatch } from "@/store";

export default Vue.extend({
  name: "VShippingList",
  props: {
    shipping: Object
  },
  data() {
    return {
      ShippingStatus,
      columns: [
        {
          title: "邮费金额",
          dataIndex: "amount",
          width: "100px"
        },
        {
          title: "状态",
          key: "status",
          dataIndex: "status",
          scopedSlots: { customRender: "status" },
          width: "150px",
          align: "center"
        },
        {
          title: "更新时间",
          key: "update_time",
          dataIndex: "update_time",
          scopedSlots: { customRender: "time" }
        },
        {
          title: "操作",
          key: "actions",
          scopedSlots: { customRender: "actions" },
          width: "200px",
          align: "center"
        }
      ]
    };
  },
  methods: {
    onRefund() {
      this.$confirm({
        title: "确认对此商品申请退运费？",
        onOk: () => {
          dispatch
            .ordersShippingStatusPatch({
              id: this.shipping.id,
              status: "refunding"
            })
            .then(() => {
              this.$message.success("申请退运费成功");
              this.$emit("change");
            });
        }
      });
    },
    onRefundConfirm() {
      this.$confirm({
        title: "确认退运费？",
        onOk: () => {
          dispatch
            .ordersShippingStatusPatch({
              id: this.shipping.id,
              status: "refunded"
            })
            .then(() => {
              this.$message.success("退运费已确认");
              this.$emit("change");
            });
        }
      });
    },
    onRefundCancel() {
      this.$confirm({
        title: "拒绝退运费？",
        onOk: () => {
          dispatch
            .ordersShippingStatusPatch({
              id: this.shipping.id,
              status: "paid"
            })
            .then(() => {
              this.$message.success("退运费已拒绝");
              this.$emit("change");
            });
        }
      });
    }
  }
});
