import Vue from "vue";
import moment from "moment";

Vue.filter("timeFormat", function(time: string) {
  if (!time) return "-";
  return moment(time).format("YYYY-MM-DD HH:mm:ss");
});

Vue.filter("dateFormat", function(time: string) {
  if (!time) return "-";
  return moment(time).format("YYYY-MM-DD");
});
