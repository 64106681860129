














import Vue, { PropType } from "vue";
import { dispatch, SubOrder } from "@/store";

export default Vue.extend({
  name: "VSubOrderRefund",
  props: {
    subOrder: Object as PropType<SubOrder>
  },
  data() {
    return {
      form: this.$form.createForm(this)
    };
  },
  methods: {
    onRefund() {
      this.$confirm({
        title: "确认对此商品订单申请退款？",
        content: "申请退款后，此平台订单下的其他商品订单仍可正常运转。",
        onOk: () => {
          dispatch
            .ordersSubOrderStatusPatch({
              subOrderNo: this.subOrder.sub_order_no,
              status: "refunding"
            })
            .then(() => {
              this.$message.success("申请退款成功");
              this.$emit("change");
            });
        }
      });
    },
    onRefundConfirm() {
      this.$confirm({
        title: "确认退款？",
        onOk: () => {
          dispatch
            .ordersSubOrderStatusPatch({
              subOrderNo: this.subOrder.sub_order_no,
              status: "refunded"
            })
            .then(() => {
              this.$message.success("退款已确认");
              this.$emit("change");
            });
        }
      });
    },
    onRefundCancel() {
      this.$confirm({
        title: "拒绝退款？",
        onOk: () => {
          dispatch
            .ordersSubOrderStatusPatch({
              subOrderNo: this.subOrder.sub_order_no,
              status: "paid"
            })
            .then(() => {
              this.$message.success("退款已拒绝");
              this.$emit("change");
            });
        }
      });
    }
  }
});
