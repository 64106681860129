















































































import Vue from "vue";
import draggable from "vuedraggable";
import { Media } from "@/store";

export default Vue.extend({
  name: "VMediaList",
  components: {
    draggable
  },
  props: {
    value: {
      type: Array,
      default: () => []
    },
    length: {
      type: Number,
      default: 1
    },
    accept: {
      type: String,
      default: "image/png,image/jpeg,image/gif,video/mp4"
    },
    width: Number,
    height: Number,
    size: Number,
    mini: Boolean
  },
  data() {
    return {
      loading: false,
      previewVisible: false,
      preview: {
        type: "image"
      }
    };
  },
  methods: {
    onPreview(media: Media) {
      this.preview = media;
      this.previewVisible = true;
    },
    onDelete(index: number) {
      this.value.splice(index, 1);
      this.$emit("change", [...this.value]);
    },
    onDragEnd() {
      this.$emit("change", [...this.value]);
    },
    onUploaded(media: Media) {
      this.$emit("change", [...this.value, media]);
    }
  },
  watch: {
    previewVisible(previewVisible) {
      if (!previewVisible && this.preview.type === "video") {
        (this.$refs.video as HTMLVideoElement).pause();
      }
    }
  }
});
