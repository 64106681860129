



















































































































import Vue from "vue";
import moment from "moment";
import _ from "loadsh";
import VProducts from "./VProducts.vue";

export default Vue.extend({
  components: { VProducts },
  name: "VFormModal",
  props: {
    name: String,
    fields: Array,
    model: Object,
    visible: Boolean,
    isAdd: Boolean,
    add: Function,
    edit: Function,
    width: String,
    labelCol: Object,
    wrapperCol: Object
  },
  data() {
    return {
      form: this.$form.createForm(this),
      loading: false
    };
  },
  watch: {
    visible(visible) {
      setTimeout(() => {
        this.form.resetFields();
        visible && this.form.setFieldsValue(this.model);
      }, 100);
    }
  },
  methods: {
    onEditOk() {
      this.form.validateFieldsAndScroll((errors, values) => {
        if (!errors) {
          values = _.mergeWith(
            this.model,
            values,
            (objValue: object, srcValue: object) => {
              if (_.isArray(objValue)) return srcValue;
            }
          );
          this.fields.map((field: any) => {
            const { dataIndex, type } = field;
            const value = _.get(values, dataIndex);
            if (!value) return;
            switch (type) {
              case "datetime":
                _.set(values, dataIndex, moment(value).format());
                break;
              case "date":
                _.set(values, dataIndex, moment(value).format("YYYY-MM-DD"));
                break;
            }
          });
          this.loading = true;
          if (this.isAdd) {
            this.add(values)
              .then((data: any) => {
                this.loading = false;
                this.$message.success("添加成功");
                this.$emit("ok", data);
              })
              .catch(() => (this.loading = false));
          } else {
            this.edit(values)
              .then((data: any) => {
                this.loading = false;
                this.$message.success("修改成功");
                this.$emit("ok", data);
              })
              .catch(() => (this.loading = false));
          }
        }
      });
    }
  }
});
