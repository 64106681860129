import { Commit } from "vuex";
import { request, url } from "@/api";
import { RootState, ListQuery } from "@/store";

export interface Coupon {
  coupon: {
    category: string;
    amount_threshold: number;
    couponrule_id: number;
    create_time: string;
    description: string;
    discount: number;
    id: number;
    is_active: boolean;
    max_amount_limit: number;
    save_amount: number;
    title: string;
    total_count: number;
    type: string;
    update_time: string;
    uuid: string;
  };
  coupon_rule: {
    create_time: string;
    end_at: string;
    id: number;
    per_claim_number: number;
    start_at: string;
    update_time: string;
    uuid: string;
    validity_date_type: string;
    validity_days: number;
    validity_goods_type: string;
    validity_goods_type_ids: number[];
  };
}

export interface CouponActivity {
  activity_type: string;
  coupon_ids: number[];
  create_time: string;
  custom_id: string;
  description: string;
  end_at: string;
  id: number;
  is_active: boolean;
  start_at: string;
  title: string;
  total_count: number;
  update_time: string;
}

export interface CouponCode {
  activity_code: string;
  claim_at: string;
  coupon_activity_id: number;
  coupon_id: number;
  create_time: string;
  id: number;
  status: string;
  send_flag: string;
  update_time: string;
  user_id: number;
}

export interface CouponCodeSend {
  content: string;
  values: [
    {
      coupon_activity_code: string;
      others: object;
      user_id: number;
    }
  ];
  id?: number;
}

export interface CouponsState {
  activityList: CouponActivity[];
  activityQuery: ListQuery;
  activityCount: number;
}

const state: CouponsState = {
  activityList: [],
  activityQuery: {
    ordering: "-id",
    is_active: true,
    page_number: 1,
    page_size: 10
  },
  activityCount: 0
};

interface Context {
  commit: Commit;
  state: CouponsState;
  rootState: RootState;
}

const actions = {
  async getList(context: Context, query: ListQuery) {
    const res: any = await request({
      url: url.coupon,
      params: query
    });
    return res;
  },
  post(context: Context, coupon: Coupon) {
    return request({
      method: "post",
      url: url.coupon,
      data: coupon
    });
  },
  patch(context: Context, coupon: Coupon) {
    return request({
      method: "patch",
      url: url.couponX.replace("{id}", coupon.coupon.id + ""),
      data: coupon
    });
  },
  getClaimInfo(context: Context, id: number) {
    return request({
      url: url.couponXClaimInfo.replace("{id}", id + "")
    });
  },
  patchCount(context: Context, coupon: Coupon) {
    return request({
      method: "patch",
      url: url.couponXCount.replace("{id}", coupon.coupon.id + ""),
      data: {
        total_count: coupon.coupon.total_count
      }
    });
  },
  async getActivityList({ commit }: Context, query: ListQuery) {
    const res: any = await request({
      url: url.couponActivity,
      params: query
    });
    commit("SET_ACTIVITY_LIST", res.results);
    commit("SET_ACTIVITY_QUERY", {
      ...query,
      page_number: res.page_number,
      page_size: res.page_size
    });
    commit("SET_ACTIVITY_COUNT", res.count);
    return res;
  },
  getActivity(context: Context, id: number) {
    return request({
      url: url.couponActivityX.replace("{id}", id + "")
    });
  },
  postActivity(context: Context, couponActivity: CouponActivity) {
    return request({
      method: "post",
      url: url.couponActivity,
      data: couponActivity
    });
  },
  patchActivity(context: Context, couponActivity: CouponActivity) {
    return request({
      method: "patch",
      url: url.couponActivityX.replace("{id}", couponActivity.id + ""),
      data: couponActivity
    });
  },
  getActivityClaimInfo(context: Context, id: number) {
    return request({
      url: url.couponActivityXClaimInfo.replace("{id}", id + "")
    });
  },
  async getCodeList(context: Context, query: ListQuery) {
    const res: any = await request({
      url: url.couponCode,
      params: query
    });
    return res;
  },
  patchCode(context: Context, couponCode: CouponCode) {
    return request({
      method: "patch",
      url: url.couponCodeX.replace("{id}", couponCode.id + ""),
      data: couponCode
    });
  },
  async bulkCreateCode(
    context: Context,
    data: {
      coupon_activity_id: number;
      coupon_id: number;
      number: number;
    }
  ) {
    const res: any = await request({
      method: "post",
      url: url.couponCodeBulk,
      data
    });
    return res;
  },
  async getCodeSendList(context: Context, query: ListQuery) {
    const res: any = await request({
      url: url.couponCodeSend,
      params: query
    });
    return res;
  },
  async getCodeSend(context: Context, id: number) {
    const res: any = await request({
      url: url.couponCodeSendX.replace("{id}", id + "")
    });
    return res;
  },
  async postCodeSend(context: Context, couponCodeSend: CouponCodeSend) {
    return request({
      method: "post",
      url: url.couponCodeSend,
      data: couponCodeSend
    });
  },
  async sendCodeSend(context: Context, id: number) {
    return request({
      method: "post",
      url: url.couponCodeSendXSend.replace("{id}", id + ""),
      data: {
        codes: [],
        is_all: true
      }
    });
  },
  getUserCouponList(context: Context, query: ListQuery) {
    return request({
      url: url.userCoupon,
      params: query
    });
  },
  cancelUserCoupon(context: Context, id: number) {
    return request({
      method: "patch",
      url: url.userCouponX.replace("{id}", id + ""),
      data: {
        status: "canceled"
      }
    });
  },
  getCouponSyncList(context: Context, query: ListQuery) {
    return request({
      url: url.couponSync,
      params: query
    });
  },
  syncUserCoupon(context: Context, id: number) {
    return request({
      method: "post",
      url: url.syncUserCouponX.replace("{id}", id + ""),
      data: {}
    });
  },
  getCouponStock(
    context: Context,
    data: { coupon_id: number; product_id: number }
  ) {
    return request({
      method: "post",
      url: url.couponStock.replace("{id}", data.coupon_id + ""),
      data: data
    });
  }
};

const mutations = {
  SET_ACTIVITY_LIST: (state: CouponsState, activityList: CouponActivity[]) => {
    state.activityList = activityList;
  },
  SET_ACTIVITY_QUERY: (state: CouponsState, activityQuery: ListQuery) => {
    state.activityQuery = activityQuery;
  },
  SET_ACTIVITY_COUNT: (state: CouponsState, activityCount: number) => {
    state.activityCount = activityCount;
  }
};

export default {
  namespaced: true,
  state,
  actions,
  mutations
};
