


























































import Vue, { PropType } from "vue";
import { mapState } from "vuex";
import { dispatch, SubOrder } from "@/store";

export default Vue.extend({
  name: "VSubOrderExpress",
  props: {
    subOrder: Object as PropType<SubOrder>
  },
  data() {
    return {
      form: this.$form.createForm(this),
      visible: false,
      lodaing: false
    };
  },
  computed: {
    ...mapState("orders", ["expressSupplier"])
  },
  methods: {
    onAddExpress() {
      this.visible = true;
      setTimeout(() => {
        this.form.resetFields();
        this.form.setFieldsValue({
          sub_order_no: this.subOrder.sub_order_no
        });
      }, 200);
    },
    onEditExpress() {
      this.visible = true;
      setTimeout(() => {
        this.form.resetFields();
        this.form.setFieldsValue({
          id: this.subOrder.express.id,
          supplier: this.subOrder.express.supplier,
          express_no: this.subOrder.express.express_no
        });
      }, 200);
    },
    onOk() {
      this.form.validateFields((errors, values) => {
        if (!errors) {
          this.lodaing = true;
          if (this.subOrder.status === "paid") {
            dispatch
              .ordersExpressPost(values)
              .then(() => {
                this.visible = false;
                this.lodaing = false;
                this.$message.success("发货成功");
                this.$emit("change");
              })
              .catch(() => {
                this.lodaing = false;
              });
          } else {
            dispatch
              .ordersExpressPatch(values)
              .then(() => {
                this.visible = false;
                this.lodaing = false;
                this.$message.success("快递信息修改成功");
                this.$emit("change");
              })
              .catch(() => {
                this.lodaing = false;
              });
          }
        }
      });
    },
    onInterceptExpress() {
      this.$confirm({
        title: "确认拦截快递？",
        content:
          "请确认货物没有被用户签收且运回仓库，拦截后此商品订单将回到待发货的状态。",
        onOk: () => {
          dispatch
            .ordersSubOrderStatusPatch({
              subOrderNo: this.subOrder.sub_order_no,
              status: "paid"
            })
            .then(() => {
              this.$message.success("快递已拦截");
              this.$emit("change");
            });
        }
      });
    }
  }
});
