import { Commit } from "vuex";
import { request, url } from "@/api";
import { RootState, ListQuery } from "@/store/modules";

export interface DrawActivity {
  id?: number;
  allow_user_ids: number[];
  code: string;
  desc: string;
  expire_time: string;
  is_delete: boolean;
  is_use: boolean;
  limit_coin: number;
  title: string;
}

const state = {};

interface Context {
  commit: Commit;
  rootState: RootState;
}

const actions = {
  getList(context: Context, query: ListQuery) {
    return request({
      url: url.draw,
      params: query
    });
  },
  getActivityList(context: Context, query: ListQuery) {
    return request({
      url: url.drawActivity,
      params: query
    });
  },
  postActivity(context: Context, activity: DrawActivity) {
    return request({
      method: "post",
      url: url.drawActivity,
      data: activity
    });
  },
  patchActivity(context: Context, activity: DrawActivity) {
    return request({
      method: "patch",
      url: url.drawActivity + "/x/" + activity.id,
      data: activity
    });
  }
};

const mutations = {};

export default {
  namespaced: true,
  state,
  actions,
  mutations
};
