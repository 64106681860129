

























import Vue from "vue";
import Cropper from "cropperjs";
import { dispatch } from "@/store";
import "cropperjs/dist/cropper.css";

export default Vue.extend({
  name: "VUpload",
  props: {
    accept: String,
    listType: String,
    withCropper: Boolean,
    width: Number,
    height: Number,
    size: Number
  },
  data() {
    return {
      loading: false,
      ossClient: null as any,
      name: "",
      type: "file",
      previewImage: "",
      cropperVisible: false,
      cropper: new Cropper(new Image()),
      progress: ""
    };
  },
  methods: {
    onUpload({ file }: { file: File }) {
      this.name = file.name;
      this.type = file.type.split("/").shift() || "file";
      if (file.size / (1024 * 1024) > this.size) {
        this.$message.error("上传文件大小超出限制！");
        return;
      }
      if (this.type === "image" && this.withCropper) {
        this.previewImage = URL.createObjectURL(file);
        this.cropperVisible = true;
      } else {
        this.upload(file);
      }
    },
    onOk() {
      const canvas = this.cropper.getCroppedCanvas({
        width: this.width,
        height: this.height
      });
      canvas.toBlob(blob => {
        if (blob) {
          const file = new File([blob as BlobPart], this.name);
          this.upload(file);
        }
      });
      this.cropperVisible = false;
    },
    upload(file: File) {
      this.loading = true;
      dispatch
        .filesUpload(file, (e: ProgressEvent) => {
          this.progress = ((e.loaded / e.total) * 100).toFixed(0) + "%";
        })
        .then(res => {
          this.progress = "";
          this.$emit("change", {
            name: this.name,
            type: this.type,
            url: res
          });
          this.loading = false;
          this.$message.success("上传成功！");
        })
        .catch(() => {
          this.loading = false;
          this.$message.error("上传失败！");
        });
    }
  },
  watch: {
    cropperVisible(visible) {
      if (visible) {
        setTimeout(() => {
          this.cropper = new Cropper(this.$refs.cropper as HTMLImageElement, {
            viewMode: 1,
            dragMode: "move",
            aspectRatio: this.width / this.height,
            minCropBoxWidth: 200,
            toggleDragModeOnDblclick: false
          });
        }, 200);
      } else {
        this.cropper && this.cropper.destroy();
      }
    }
  }
});
