import app, { AppState } from "@/store/modules/app";
import files from "@/store/modules/files";
import products, { ProductsState } from "@/store/modules/products";
import orders, { OrdersState } from "@/store/modules/orders";
import notifications, {
  NotificationsState
} from "@/store/modules/notifications";
import coupons, { CouponsState } from "@/store/modules/coupons";
import AMNumbers, { AMNumbersState } from "@/store/modules/AMNumbers";
import SNCodes, { SNCodesState } from "@/store/modules/SNCodes";
import warehouse, { WarehouseState } from "@/store/modules/warehouse";
import settings, { SettingsState } from "@/store/modules/settings";
import draw from "@/store/modules/draw";

export interface RootState {
  app: AppState;
  products: ProductsState;
  orders: OrdersState;
  notifications: NotificationsState;
  coupons: CouponsState;
  AMNumbers: AMNumbersState;
  SNCodes: SNCodesState;
  warehouse: WarehouseState;
  settings: SettingsState;
}

export interface ListQuery {
  ordering: string; // 使用 "-"来表示倒序排序，如 -id
  page_number: number;
  page_size: number;
  is_active?: boolean;
  status?: string; // 商品状态
  user_ids__is_null?: boolean;
  ids?: number[];
  name__contains?: string;
  id?: number | string;
  coupon_id?: number;
  coupon_activity_id?: number;
  product_code?: string;
}

export interface Address {
  city: string;
  consignee: string;
  country: string;
  create_time: string;
  detail: string;
  district: string;
  is_default: boolean;
  phone: string;
  province: string;
  update_time: string;
  id?: number;
  user_id?: number;
}

export default {
  app,
  files,
  products,
  orders,
  notifications,
  coupons,
  AMNumbers,
  SNCodes,
  warehouse,
  settings,
  draw
};
