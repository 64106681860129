































































import Vue from "vue";
import XLSX from "xlsx";
import moment from "moment";
import { download, xlsx } from "@/utils";
import { dispatch } from "@/store";

export default Vue.extend({
  name: "VSnImport",
  props: {
    productList: Array,
    producerList: Array
  },
  data() {
    return {
      visible: false,
      loading: false,
      form: {
        product_id: null,
        producer_id: null,
        SNCodeList: [] as any[]
      },
      rules: {
        product_id: [
          {
            required: true,
            message: "请选择产品"
          }
        ],
        producer_id: [
          {
            required: true,
            message: "请选择制造商"
          }
        ],
        SNCodeList: [
          {
            required: true,
            message: "请上传 SN 码",
            trigger: "blur"
          }
        ]
      }
    };
  },
  computed: {
    uploadHelp(): string {
      const length = this.form.SNCodeList.length;
      return length ? `已上传 ${length} 条 SN 码数据` : "";
    }
  },
  methods: {
    onUpload({ file }: { file: File }) {
      const reader = new FileReader();
      reader.onload = (e: any) => {
        const data = new Uint8Array(e.target.result);
        const workbook = XLSX.read(data, { type: "array" });
        const name = workbook.SheetNames[0];
        const sheet = workbook.Sheets[name];
        const list = XLSX.utils.sheet_to_json(sheet);
        this.form.SNCodeList = list.map((item: any, index: number) => {
          if (!item["SN码"] || !item["SN码"].trim()) {
            this.$message.error(
              "第 " +
                (index + 2) +
                " 行 SN 码有误，请仔细检查，参考模板样例数据！",
              10
            );
            throw new Error();
          }
          if (
            !item["生产日期"] ||
            moment(item["生产日期"], "YYYYMMDD").format("YYYY-MM-DD") ===
              "Invalid date"
          ) {
            this.$message.error(
              "第 " +
                (index + 2) +
                " 行生产日期有误，请仔细检查，参考模板样例数据！",
              10
            );
            throw new Error();
          }
          return {
            sn_code: item["SN码"].trim(),
            security_code: item["防伪码"] ? item["防伪码"].trim() : null,
            date_of_manufacture: moment(item["生产日期"], "YYYYMMDD").format(
              "YYYY-MM-DD"
            )
          };
        });
        (this.$refs.form as any).validateField("SNCodeList");
      };
      reader.onerror = () => {
        this.$message.error("SN 码上传失败");
        this.form.SNCodeList = [] as any[];
        (this.$refs.form as any).validateField("SNCodeList");
      };
      reader.readAsArrayBuffer(file);
    },
    onDownload() {
      const file = xlsx([
        {
          name: "SN码导入模板",
          data: [
            ["SN码", "防伪码", "生产日期", "(表头请勿删除)"],
            [
              moment().format("YYYY") + "0000000000",
              "xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx0000",
              moment().format("YYYY0101"),
              "(删除样例数据)"
            ]
          ],
          cols: [{ wch: 18 }, { wch: 40 }, { wch: 10 }, { wch: 18 }]
        }
      ]);
      download(file, "SN码导入模板.xlsx");
    },
    onOk() {
      (this.$refs.form as any).validate((valid: boolean) => {
        if (valid) {
          const { SNCodeList, product_id, producer_id } = this.form;
          const SNCodes = SNCodeList.map(item => ({
            ...item,
            product_id,
            producer_id
          }));
          this.loading = true;
          dispatch
            .SNCodesImport(SNCodes)
            .then(() => {
              this.loading = false;
              this.$message.success("SN 码导入成功");
              (this.$refs.form as any).resetFields();
              this.visible = false;
              this.$emit("change", {});
            })
            .catch(error => {
              this.loading = false;
              this.$message.destroy();
              if (error.successes && error.successes.length) {
                this.$message.success(
                  error.successes.length + " 条数据导入成功",
                  5
                );
              }
              if (error.errors && error.errors.length) {
                this.$message.error(
                  error.errors.length + " 条数据导入失败，失败列表正在下载…",
                  5
                );
                const data = [
                  ["SN码", "防伪码", "生产日期", "所在行数", "失败原因"]
                ];
                error.errors.map((item: any) => {
                  data.push([
                    item.data.sn_code,
                    item.data.security_code,
                    item.data.date_of_manufacture,
                    item.index + 1,
                    item.message.includes("key value violates unique")
                      ? "SN 码或防伪码已存在"
                      : "数据有误，请仔细检查"
                  ]);
                });
                const file = xlsx([
                  {
                    name: "失败原因",
                    data,
                    cols: [
                      { wch: 18 },
                      { wch: 40 },
                      { wch: 10 },
                      { wch: 5 },
                      { wch: 40 }
                    ]
                  }
                ]);
                download(file, "SN 码导入失败列表.xlsx");
              } else {
                this.$message.error("SN 码导入失败");
              }
            });
        }
      });
    },
    onCancel() {
      (this.$refs.form as any).resetFields();
      this.visible = false;
    }
  }
});
