







































































import Vue, { PropType } from "vue";
import { dispatch, SubOrder, RefundOrder } from "@/store";

export default Vue.extend({
  name: "VSubOrderRefundOrder",
  props: {
    subOrder: Object as PropType<SubOrder>,
    orderNo: String
  },
  data() {
    return {
      form: this.$form.createForm(this),
      visible: false,
      lodaing: false,
      number: 1,
      amount: 0
    };
  },
  methods: {
    onValuesChange(props: any, { number, amount }: RefundOrder) {
      this.number = number >= 0 ? number : this.number;
      this.amount = amount >= 0 ? amount : this.amount;
    },
    onAddRefundOrder() {
      this.visible = true;
      setTimeout(() => {
        this.form.resetFields();
      }, 200);
    },
    onOk() {
      this.form.validateFields((errors, values) => {
        if (!errors) {
          this.lodaing = true;
          dispatch
            .ordersRefundOrderPost({
              ...values,
              order_no: this.orderNo,
              sub_order_no: this.subOrder.sub_order_no,
              status: "processing",
              type: "sub_order"
            })
            .then(() => {
              this.visible = false;
              this.lodaing = false;
              this.$message.success("添加售后单成功");
              this.$emit("change");
            })
            .catch(() => {
              this.lodaing = false;
            });
        }
      });
    }
  },
  mounted() {
    this.form = this.$form.createForm(this, {
      onValuesChange: this.onValuesChange
    });
  }
});
