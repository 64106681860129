




















































import Vue, { PropType } from "vue";
import { dispatch, Order } from "@/store";

export default Vue.extend({
  name: "VOrderRefundOrder",
  props: {
    order: Object as PropType<Order>
  },
  data() {
    return {
      form: this.$form.createForm(this),
      visible: false,
      lodaing: false
    };
  },
  methods: {
    onAddRefundOrder() {
      this.visible = true;
      setTimeout(() => {
        this.form.resetFields();
      }, 200);
    },
    onOk() {
      this.form.validateFields((errors, values) => {
        if (!errors) {
          this.lodaing = true;
          dispatch
            .ordersRefundOrderPost({
              ...values,
              number: 1,
              order_no: this.order.order.order_no,
              status: "processing",
              type: "custom"
            })
            .then(() => {
              this.visible = false;
              this.lodaing = false;
              this.$message.success("添加售后单成功");
              this.$emit("change");
            })
            .catch(() => {
              this.lodaing = false;
            });
        }
      });
    }
  }
});
