










































































import Vue from "vue";
import XLSX from "xlsx";
import moment from "moment";
import { download, xlsx } from "@/utils";
import { dispatch } from "@/store";
import { Currencies } from "@/enum";

export default Vue.extend({
  name: "VWarehouseImport",
  props: {
    platformList: Array,
    producerList: Array
  },
  data() {
    return {
      Currencies,
      visible: false,
      loading: false,
      form: {
        platform_id: null,
        producer_id: null,
        currency: null,
        orderList: [] as any[]
      },
      rules: {
        platform_id: [
          {
            required: true,
            message: "请选择产品"
          }
        ],
        producer_id: [
          {
            required: true,
            message: "请选择制造商"
          }
        ],
        currency: [
          {
            required: true,
            message: "请选择货币类型"
          }
        ],
        orderList: [
          {
            required: true,
            message: "请上传订单",
            trigger: "blur"
          }
        ]
      }
    };
  },
  computed: {
    uploadHelp(): string {
      const length = this.form.orderList.length;
      return length ? `已上传 ${length} 条订单数据` : "";
    }
  },
  methods: {
    onUpload({ file }: { file: File }) {
      const reader = new FileReader();
      reader.onload = (e: any) => {
        const data = new Uint8Array(e.target.result);
        const workbook = XLSX.read(data, { type: "array" });
        const name = workbook.SheetNames[0];
        const sheet = workbook.Sheets[name];
        const list = XLSX.utils.sheet_to_json(sheet);
        if (list.length > 2000) {
          this.$message.error(`请单次导入小于 2000 条数据`, 10);
          throw new Error();
        }
        this.form.orderList = list.map((item: any, index: number) => {
          const checkNULL = (title: string) => {
            const value = item[title];
            if (!value || !value.toString().trim()) {
              this.$message.error(`第 ${index + 2} 行 “${title}” 不存在！`, 10);
              throw new Error();
            }
          };
          const getString = (title: string) =>
            item[title] ? (item[title] + "").trim() : null;
          const getNumber = (title: string) => {
            if (item[title]) {
              if (isNaN(Number(item[title]))) {
                this.$message.error(
                  `第 ${index + 2} 行“${title}”不是正确的数字！`,
                  10
                );
                throw new Error();
              }
              return Number(item[title]);
            } else {
              return null;
            }
          };
          const getTime = (title: string) => {
            if (item[title]) {
              const time = moment(item[title]).format("YYYY-MM-DDTHH:mm:ssZ");
              if (time === "Invalid date") {
                this.$message.error(
                  `第 ${index +
                    2} 行“${title}”不是正确的时间格式 [2000-01-01 00:00:00 +0800] ！`,
                  10
                );
                throw new Error();
              }
              return time;
            } else {
              return null;
            }
          };
          checkNULL("平台订单号");
          checkNULL("商品编码");
          checkNULL("物品名称");
          checkNULL("数量");
          return {
            transaction_id: getString("商户订单号"),
            order_no: getString("平台订单号"),
            sub_order_no: getString("商品订单号"),
            email: getString("客户邮箱"),
            user_id: getNumber("客户ID"),
            address_consignee: getString("收件人姓名"),
            address_phone: getString("手机号"),
            address_country: getString("国家"),
            address_province: getString("省"),
            address_city: getString("市"),
            address_district: getString("区"),
            address_detail: getString("地址"),
            sku_name: getString("商品名称"),
            goods_code: getString("商品编码"),
            goods_name: getString("物品名称"),
            amount: getNumber("订单含税单价"),
            number: getNumber("数量"),
            purchase_time: getTime("下订单日期"),
            payment_time: getTime("付款时间"),
            status: getString("订单状态"),
            total_amount: getNumber("订单含税总金额"),
            aggregate_amount: getNumber("订单支付总金额"),
            payment_method: getString("收款渠道"),
            refund_time: getTime("退款时间"),
            refunding_amount: getNumber("商品订单退款中金额"),
            refunded_amount: getNumber("商品订单已退款金额"),
            aggregate_refunding_amount: getNumber("平台订单退款中总金额"),
            aggregate_refund_amount: getNumber("平台订单退款总金额"),
            express_time: getTime("发货时间"),
            express_supplier: getString("物流商名称"),
            express_no: getString("物流号"),
            security_code: getString("防伪码"),
            sn_code: getString("SN码")
          };
        });
        (this.$refs.form as any).validateField("orderList");
      };
      reader.onerror = () => {
        this.$message.error("订单上传失败");
        this.form.orderList = [] as any[];
        (this.$refs.form as any).validateField("orderList");
      };
      reader.readAsArrayBuffer(file);
    },
    onDownload() {
      const file = xlsx([
        {
          name: "订单仓库导入模板",
          data: [
            [
              "发售平台",
              "供应商名称",
              "商户订单号",
              "平台订单号",
              "商品订单号",
              "客户邮箱",
              "客户ID",
              "收件人姓名",
              "手机号",
              "国家",
              "省",
              "市",
              "区",
              "地址",
              "商品名称",
              "商品编码",
              "物品名称",
              "订单含税单价",
              "数量",
              "下订单日期",
              "付款时间",
              "订单状态",
              "订单含税总金额",
              "订单支付总金额",
              "收款渠道",
              "退款时间",
              "商品订单退款中金额",
              "商品订单已退款金额",
              "平台订单退款中总金额",
              "平台订单退款总金额",
              "发货时间",
              "物流商名称",
              "物流号",
              "防伪码",
              "SN码"
            ]
          ],
          cols: [
            { wch: 10 },
            { wch: 10 },
            { wch: 10 },
            { wch: 10 },
            { wch: 10 },
            { wch: 30 },
            { wch: 10 },
            { wch: 10 },
            { wch: 10 },
            { wch: 10 },
            { wch: 10 },
            { wch: 10 },
            { wch: 10 },
            { wch: 10 },
            { wch: 30 },
            { wch: 30 },
            { wch: 30 },
            { wch: 15 },
            { wch: 10 },
            { wch: 30 },
            { wch: 30 },
            { wch: 10 },
            { wch: 15 },
            { wch: 15 },
            { wch: 10 },
            { wch: 10 },
            { wch: 20 },
            { wch: 20 },
            { wch: 20 },
            { wch: 20 },
            { wch: 10 },
            { wch: 10 },
            { wch: 10 },
            { wch: 30 },
            { wch: 30 }
          ]
        }
      ]);
      download(file, "订单仓库导入模板.xlsx");
    },
    onOk() {
      (this.$refs.form as any).validate((valid: boolean) => {
        if (valid) {
          const { orderList, platform_id, producer_id, currency } = this.form;
          const orders = orderList.map(item => ({
            ...item,
            platform_id,
            producer_id,
            currency
          }));
          this.loading = true;
          dispatch
            .warehouseImport(orders)
            .then(() => {
              this.loading = false;
              this.$message.success("订单导入成功");
              (this.$refs.form as any).resetFields();
              this.visible = false;
              this.$emit("change", {});
            })
            .catch(error => {
              this.loading = false;
              this.$message.destroy();
              if (
                error.message &&
                error.message.includes(
                  "value is greater than the required length"
                )
              ) {
                this.$message.error(
                  error.message.replace(
                    /(\d*) .+"(.+)".*/,
                    "第 $1 行数据 $2 过长，请仔细检查"
                  ),
                  10
                );
              }
            });
        }
      });
    },
    onCancel() {
      (this.$refs.form as any).resetFields();
      this.visible = false;
    }
  }
});
