export const isTest = //false;
  location.href.includes("test.angrymiao.com") ||
  !location.href.includes(".angrymiao.com");
const base = `https://mall-admin${isTest ? "-test" : ""}.angrymiao.com/api`;
const authBase = `https://auth${isTest ? "-test-v2" : ""}.angrymiao.com/api`;
const verifyBase = `https://verify-admin${
  isTest ? "-test" : ""
}.angrymiao.com/api`;
const fcBase = `https://fc.angrymiao.com/www/${isTest ? "test" : "prod"}`;
const mallBase = `https://mall${isTest ? "-test" : ""}.angrymiao.com`;
const wwwOssBase = "https://cdn-www.angrymiao.com";

export default {
  mallCouponActivity: `${mallBase}/coupon-activity/`, // 商城活动页面
  mallCouponCodeClaim: `/coupons`, // 商城优惠券列表页面
  mallAMCodeClaim: `/coupons/am`, // 商城必购码列表页面
  mallDrawActivity: `${mallBase}/draw-activity/`, // 商城抽奖活动页面

  auth: `https://auth${isTest ? "-test" : ""}.angrymiao.com/`, // 登录页面
  authRefresh: `https://auth${isTest ? "-test" : ""}.angrymiao.com/refresh/`, // 登录页面

  // authOauth2Url: `${authBase}/passport/oauth2-url`, // 第三方登录链接获取
  // authOauth2SignIn: `${authBase}/passport/oauth2-callback`, // 第三方登录验证
  // authSendSMS: `${authBase}/passport/sign-in/send-sms`, // 获取短信登录验证码
  // authPhoneSignIn: `${authBase}/passport/sign-in/phone`, // 手机短信登录
  // authRefreshToken: `${authBase}/passport/refresh-token`, // 刷新 token
  authProfile: `${authBase}/profile`, // 获取用户profile

  uploadSign: `${base}/upload/sign`, // OSS 登录

  product: `${base}/product`, // 获取商品
  productShare: `${base}/product/share`, // 获取商品分享

  productSync: `${base}/hupun/sync-product`, // 同步商品到万里牛

  productsHidden: `https://mall${
    isTest ? "-test" : ""
  }.angrymiao.com/products/hidden/`, // 隐藏商品

  cartCount: `${base}/cart/count`, // 商品加购数

  sale: `${base}/sale`, // 商品销量

  saleLimit: `${base}/sale/limit`, // 商品售卖限制

  order: `${base}/order`, // 平台订单

  orderExport: `${base}/exporter/order`, // 平台订单导出

  orderImport: `${base}/importer/bulk-update-order-status`, // 平台订单状态导入

  orderTemplate: `${base}/importer/bulk-update-order-status-template`, // 平台订单状态模板

  orderSync: `${base}/hupun/sync-order`, // 同步订单到万里牛

  subOrder: `${base}/suborder`, // 商品订单

  subOrderBulk: `${base}/suborder-bulk`, // 商品订单批处理

  shipping: `${base}/shipping`, // 运费单

  refundOrder: `${base}/refund-order`, // 售后单

  express: `${base}/express`, // 快递

  expressSupplier: `${base}/express-supplier`, // 快递供应商

  expressSupplierX: `${base}/express-supplier/x/`, // 快递供应商详情

  expressImport: `${base}/importer/express`, // 快递信息导入

  expressTemplate: `${base}/importer/express-template`, // 快递信息模板

  notification: `${base}/notification`, // 消息通知

  coupon: `${base}/coupon`, // 优惠券
  couponX: `${base}/coupon/x/{id}`, // 优惠券详情
  couponXClaimInfo: `${base}/coupon/x/{id}/claim-info`, // 优惠券领取情况
  couponXCount: `${base}/coupon/x/{id}/coupon-number`, // 更新优惠券数量
  couponActivity: `${base}/coupon-activity`, // 优惠券活动
  couponActivityX: `${base}/coupon-activity/x/{id}`, // 优惠券活动详情
  couponActivityXClaimInfo: `${base}/coupon-activity/x/{id}/claim-info`, // 优惠券活动领取情况
  couponCode: `${base}/coupon-activity-code`, // 优惠券码
  couponCodeX: `${base}/coupon-activity-code/x/{id}`, // 优惠券码详情
  couponCodeBulk: `${base}/coupon-activity-code/bulk-create`, // 批量生成优惠券码
  couponCodeSend: `${base}/coupon-send`, // 批量发送优惠券码
  couponCodeSendX: `${base}/coupon-send/x/{id}`, // 优惠券码批量发送详情
  couponCodeSendXSend: `${base}/coupon-send/x/{id}/send`, // 优惠券码批量发送
  couponStock: `${base}/product/coupon_stock`, // 获取优惠券库存
  userCoupon: `${base}/user-coupon`, // 用户优惠券列表
  userCouponX: `${base}/user-coupon/x/{id}`, // 更新用户优惠券
  couponSync: `${base}/coupon-sync`, // 获取 优惠券发送
  syncUserCouponX: `${base}/coupon-sync/x/{id}/sync-user-coupon`, // 同步优惠券信息到用户优惠券

  number: `${base}/nft-number/orders`, // AM Hatsu 选号
  numberSection: `${base}/nft-number/section`, // AM Hatsu 选号号段

  SNCode: `${verifyBase}/device`, // SN 码
  SNCodeGenerator: `${verifyBase}/sn-code-generator`, // SN 码生成
  SNCodeImport: `${verifyBase}/device-bulk`, // SN 码导入
  SNProducer: `${verifyBase}/producer`, // SN 制造商
  SNProduct: `${verifyBase}/product`, // SN 产品信息

  warehouseOrder: `${base}/warehouse/order`, // 仓库 订单
  warehouseOrderImport: `${base}/warehouse/order-bulk`, // 仓库 订单导入
  warehousePlatform: `${base}/warehouse/platform`, // 仓库 平台
  warehouseProducer: `${base}/warehouse/producer`, // 仓库 制造商

  settings: `${base}/settings`, // 设置

  specialList: `${base}/special-list`, // 特殊用户名单

  wwwConfig: `${wwwOssBase}/${isTest ? "test_config" : "config"}/settings.json`, // 官网配置文件
  wwwConfigUpload: `${fcBase}/config-upload`, // 官网配置文件上传
  wwwRequest: `${base}/product-user-collection`, // 获取商品用户想要信息

  keyboardCustom: `${base}/keyboard-custom`, // 键盘自定义数据列表

  draw: `${base}/draw`, // 键盘抽奖数据列表
  drawActivity: `${base}/draw_activity` // 键盘抽奖数据列表
};
