


































import Vue from "vue";
import { dispatch, RefundOrder } from "@/store";
import { RefundOrderStatus } from "@/enum";

export default Vue.extend({
  name: "VRefundOrderList",
  props: {
    orderNo: String
  },
  data() {
    return {
      RefundOrderStatus,
      columns: [
        {
          title: "售后单",
          key: "id",
          dataIndex: "id",
          width: "60px",
          align: "center"
        },
        {
          title: "所属商品订单号",
          key: "sub_order.sub_order_no",
          dataIndex: "sub_order.sub_order_no",
          width: "250px"
        },
        {
          title: "回退商品数量",
          key: "number",
          dataIndex: "number",
          customRender: (text: string, refundOrder: RefundOrder) => {
            if (refundOrder.type === "sub_order") {
              return refundOrder.number;
            } else {
              return "-";
            }
          },
          width: "120px"
        },
        {
          title: "退款单价",
          key: "amount",
          dataIndex: "amount",
          width: "120px"
        },
        {
          title: "退款合计",
          key: "total",
          customRender: (text: string, refundOrder: RefundOrder) => {
            return refundOrder.amount * refundOrder.number;
          },
          width: "120px"
        },
        {
          title: "状态",
          key: "status",
          dataIndex: "status",
          scopedSlots: { customRender: "status" },
          width: "120px",
          align: "center"
        },
        {
          title: "更新时间",
          key: "update_time",
          dataIndex: "update_time",
          scopedSlots: { customRender: "time" },
          width: "160px"
        },
        {
          title: "备注",
          key: "remark",
          dataIndex: "remark"
        },
        {
          title: "操作",
          key: "actions",
          scopedSlots: { customRender: "actions" },
          width: "120px",
          align: "center"
        }
      ],
      list: []
    };
  },
  methods: {
    onPush(refundOrder: RefundOrder) {
      this.$confirm({
        title: "确认开启售后单？",
        onOk: () => {
          dispatch
            .ordersRefundOrderStatusPatch({
              ...refundOrder,
              status: "processing"
            })
            .then(() => {
              this.$message.success("售后单已开始");
              dispatch.ordersRefundOrderGetList(this.orderNo).then(res => {
                this.list = res.results;
              });
            });
        }
      });
    },
    onConfirm(refundOrder: RefundOrder) {
      this.$confirm({
        title: "确认完成售后单？",
        onOk: () => {
          dispatch
            .ordersRefundOrderStatusPatch({
              ...refundOrder,
              status: "accepted"
            })
            .then(() => {
              this.$message.success("售后单已完成");
              dispatch.ordersRefundOrderGetList(this.orderNo).then(res => {
                this.list = res.results;
              });
            });
        }
      });
    },
    onCancel(refundOrder: RefundOrder) {
      this.$confirm({
        title: "确认拒绝售后单？",
        onOk: () => {
          dispatch
            .ordersRefundOrderStatusPatch({
              ...refundOrder,
              status: "rejected"
            })
            .then(() => {
              this.$message.success("售后单已拒绝");
              dispatch.ordersRefundOrderGetList(this.orderNo).then(res => {
                this.list = res.results;
              });
            });
        }
      });
    }
  },
  mounted() {
    dispatch.ordersRefundOrderGetList(this.orderNo).then(res => {
      this.list = res.results;
    });
  }
});
