


















































































































































































































































import Vue from "vue";
import { mapState } from "vuex";
import { dispatch } from "@/store";
import { download, xlsx } from "@/utils";
import { OrderStatus, SubOrderStatus, ShippingStatus } from "@/enum";

export default Vue.extend({
  name: "VOrderListFilter",
  data() {
    return {
      OrderStatus,
      SubOrderStatus,
      ShippingStatus,
      form: this.$form.createForm(this),
      labelCol: { span: 5 },
      wrapperCol: { span: 14 },
      expressSupplierFields: [
        {
          title: "供应商代码",
          dataIndex: "code",
          rules: [
            {
              required: true,
              whitespace: true,
              message: "请输入供应商代码",
              trigger: "blur"
            },
            {
              max: 40,
              message: "请输入少于 40 个字符",
              trigger: "blur"
            }
          ],
          immutable: true
        },
        {
          title: "供应商名称",
          dataIndex: "name",
          rules: [
            {
              required: true,
              whitespace: true,
              message: "请输入供应商名称",
              trigger: "blur"
            },
            {
              max: 40,
              message: "请输入少于 40 个字符",
              trigger: "blur"
            }
          ]
        }
      ],
      dispatch
    };
  },
  computed: {
    ...mapState("orders", {
      query: "query"
    })
  },
  methods: {
    onReset() {
      this.form.resetFields();
      const filter = this.form.getFieldsValue();
      this.$emit("change", filter);
    },
    onFilterChange() {
      const filter = this.form.getFieldsValue();
      this.$emit("change", filter);
    },
    onOrderExport() {
      this.$message.info("订单导出中，请稍候…");
      dispatch
        .ordersExport(this.query)
        .then(() => {
          this.$message.success("订单导出成功，正在下载…");
        })
        .catch(() => {
          this.$message.error("订单导出失败");
        });
    },
    onOrderUpload({ file }: { file: File }) {
      this.$message.info("订单信息导入中…");
      dispatch
        .ordersImport(file)
        .then(() => {
          this.$message.success("订单信息导入成功");
        })
        .catch(error => {
          if (!error.header) {
            this.$message.error("订单信息导入失败");
            return;
          } else {
            this.$message.error(
              error.body.length + " 条导入失败，失败列表正在下载…",
              3
            );
          }
          const data = [error.header];
          error.body.map((item: any) => {
            let last = item[item.length - 1];
            last = last.includes("not allow to update this order")
              ? "订单不允许修改"
              : "订单号不存在或订单状态有误";
            item[item.length - 1] = last;
            data.push(item);
          });
          const file = xlsx([
            {
              name: "失败原因",
              data,
              cols: [{ wch: 5 }, { wch: 30 }, { wch: 15 }, { wch: 30 }]
            }
          ]);
          download(file, "订单信息导入失败列表.xlsx");
        });
    },
    onOrderDownload() {
      dispatch.ordersTemplate();
    },
    onExpressUpload({ file }: { file: File }) {
      this.$message.info("快递信息导入中…");
      dispatch
        .ordersExpressImport(file)
        .then(() => {
          this.$message.success("快递信息导入成功");
        })
        .catch(error => {
          if (!error.header) {
            this.$message.error("快递信息导入失败");
            return;
          } else {
            this.$message.error(
              error.body.length + " 条导入失败，失败列表正在下载…",
              3
            );
          }
          const data = [error.header];
          error.body.map((item: any) => {
            let last = item[item.length - 1];
            last = last.includes("suborder status should be paid or shipping")
              ? "子订单状态必须为待发货或已发货"
              : last;
            item[item.length - 1] = last;
            data.push(item);
          });
          const file = xlsx([
            {
              name: "失败原因",
              data,
              cols: [
                { wch: 5 },
                { wch: 30 },
                { wch: 30 },
                { wch: 8 },
                { wch: 60 }
              ]
            }
          ]);
          download(file, "快递信息导入失败列表.xlsx");
        });
    },
    onExpressDownload() {
      dispatch.ordersExpressTemplate();
    }
  }
});
