import { Commit } from "vuex";
import { request, url } from "@/api";
import { RootState, ListQuery, Address } from "@/store";

export interface Express {
  address_info: any;
  create_time: string;
  express_no: string;
  id?: number;
  sub_order_id: number;
  supplier: string;
  update_time: string;
}

export interface RefundOrder {
  amount: number;
  number: number;
  order_no: string;
  sub_order_no: string;
  remark: string;
  type: "sub_order" | "custom";
  refund_order_no?: string;
  status?: "processing" | "accepted" | "rejected";
}

export interface SubOrder {
  address_info: any;
  amount: number;
  coin: number;
  discount_amount: number;
  create_time: string;
  express: Express;
  extra_amount: number;
  has_express: boolean;
  id: number;
  number: number;
  order_id: number;
  phone: string;
  product_id: number;
  product_snapshot: any;
  sku_id: number;
  status: string;
  sub_order_no: string;
  update_time: string;
}

export interface Order {
  order: {
    id: number;
    amount: number;
    create_time: string;
    discount_amount: number;
    order_no: string;
    payment_method: string;
    refund_time: string;
    refund_info: any[];
    shipping: number;
    status: string;
    transaction_id: string;
    user_id: number;
    comment: string;
  };
  shipping_info?: {
    status: string;
  };
  sub_orders: SubOrder[];
}

export interface ExpressSupplier {
  code: string;
  name: string;
}

export interface OrdersState {
  list: Order[];
  query: ListQuery;
  count: number;
  expressSupplier: ExpressSupplier[];
}

const state: OrdersState = {
  list: [],
  query: {
    ordering: "-create_time",
    page_number: 1,
    page_size: 10
  },
  count: 0,
  expressSupplier: []
};

interface Context {
  commit: Commit;
  state: OrdersState;
  rootState: RootState;
}

const actions = {
  async getList({ commit }: Context, query: ListQuery) {
    const res: any = await request({
      url: url.order,
      params: query
    });
    commit("SET_LIST", res.results);
    commit("SET_QUERY", {
      ...query,
      page_number: res.page_number,
      page_size: res.page_size
    });
    commit("SET_COUNT", res.count);
    return res;
  },
  export(context: Context, query: ListQuery) {
    return request({
      url: url.orderExport,
      params: query,
      responseType: "blob"
    });
  },
  import(context: Context, file: File) {
    const formData = new FormData();
    formData.append("file", file);
    return request({
      method: "POST",
      url: url.orderImport,
      data: formData
    });
  },
  template() {
    return request({
      url: url.orderTemplate,
      responseType: "blob"
    });
  },
  get(context: Context, orderNo: string) {
    return request({
      url: url.order + "/" + orderNo
    });
  },
  patch(context: Context, order: Order) {
    return request({
      method: "patch",
      url: url.order + "/" + order.order.order_no,
      data: {
        ...order.order
      }
    });
  },
  statusPatch(context: Context, order: Order) {
    return request({
      method: "patch",
      url: url.order + "/" + order.order.order_no + "/status",
      data: {
        status: order.order.status,
        refund_info: order.order.refund_info
      }
    });
  },
  amountPatch(context: Context, order: Order) {
    return request({
      method: "patch",
      url: url.order + "/" + order.order.order_no + "/amount",
      data: {
        amount: order.order.amount,
        shipping: order.order.shipping
      }
    });
  },
  limitDelete(context: Context, order: Order) {
    return request({
      method: "delete",
      url: url.order + "/" + order.order.order_no + "/limit"
    });
  },
  addressPatch(
    context: Context,
    { subOrderNo, address }: { subOrderNo: string; address: Address }
  ) {
    return request({
      method: "patch",
      url: url.subOrder + "/" + subOrderNo + "/address",
      data: {
        address_info: address
      }
    });
  },
  subOrderStatusPatch(
    context: Context,
    { subOrderNo, status }: { subOrderNo: string; status: string }
  ) {
    return request({
      method: "patch",
      url: url.subOrder + "/" + subOrderNo + "/status",
      data: {
        status
      }
    });
  },
  shippingStatusPatch(
    context: Context,
    { id, status }: { id: string; status: string }
  ) {
    return request({
      method: "patch",
      url: url.shipping + "/" + id + "/status",
      data: {
        status
      }
    });
  },
  expressPost(context: Context, express: Express) {
    return request({
      method: "post",
      url: url.express,
      data: express
    });
  },
  expressPatch(context: Context, express: Express) {
    return request({
      method: "patch",
      url: url.express + "/" + express.id,
      data: express
    });
  },
  expressImport(context: Context, file: File) {
    const formData = new FormData();
    formData.append("file", file);
    return request({
      method: "POST",
      url: url.expressImport,
      data: formData
    });
  },
  expressTemplate() {
    return request({
      url: url.expressTemplate,
      responseType: "blob"
    });
  },
  expressSupplier() {
    return request({
      url: url.expressSupplier
    });
  },
  expressSupplierPost(context: Context, data: ExpressSupplier) {
    return request({
      method: "post",
      url: url.expressSupplier,
      data
    });
  },
  expressSupplierDelete(context: Context, data: ExpressSupplier) {
    return request({
      method: "delete",
      url: url.expressSupplierX + data.code,
      data
    });
  },
  async expressSupplierAll({ commit }: Context) {
    const res: any = await request({
      url: url.expressSupplier,
      params: { page_size: 1000 }
    });
    commit("SET_EXPRESS_SUPPLIER", res.results);
  },
  refundOrderGetList(context: Context, orderNo: string) {
    return request({
      url: url.refundOrder,
      params: {
        order_no: orderNo,
        page_number: 1,
        page_size: 1000
      }
    });
  },
  refundOrderPost(context: Context, refundOrder: RefundOrder) {
    return request({
      method: "post",
      url: url.refundOrder,
      data: refundOrder
    });
  },
  refundOrderStatusPatch(context: Context, refundOrder: RefundOrder) {
    return request({
      method: "patch",
      url: url.refundOrder + "/" + refundOrder.refund_order_no + "/status",
      data: refundOrder
    });
  },
  sync(context: Context, order_nos: string[]) {
    return request({
      method: "post",
      url: url.orderSync,
      data: {
        order_nos
      }
    });
  }
};

const mutations = {
  SET_LIST: (state: OrdersState, list: Order[]) => {
    state.list = list;
  },
  SET_QUERY: (state: OrdersState, query: ListQuery) => {
    state.query = query;
  },
  SET_COUNT: (state: OrdersState, count: number) => {
    state.count = count;
  },
  SET_EXPRESS_SUPPLIER: (
    state: OrdersState,
    expressSupplier: ExpressSupplier[]
  ) => {
    state.expressSupplier = expressSupplier;
  }
};

export default {
  namespaced: true,
  state,
  actions,
  mutations
};
