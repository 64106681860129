





























import Vue from "vue";
import moment from "moment";
import { mapState } from "vuex";
import { dispatch, Product } from "@/store";

export default Vue.extend({
  name: "VProductWhiteList",
  data() {
    return {
      moment,
      loading: false,
      form: this.$form.createForm(this),
      labelCol: { span: 5 },
      wrapperCol: { span: 14 },
      product: { product: {} } as Product
    };
  },
  computed: mapState({
    id() {
      return this.$route.params.id;
    }
  }),
  methods: {
    onSubmit() {
      if (this.loading) {
        return;
      }
      this.form.validateFieldsAndScroll(
        { scroll: { offsetTop: 200 } },
        (errors, values) => {
          if (!errors) {
            this.loading = true;
            dispatch
              .productsPatchWhiteList({
                id: Number(this.id),
                ids: values.ids.map((id: string) => Number(id))
              })
              .then(() => {
                this.$message.success("白名单已修改");
                this.loading = false;
              })
              .catch(() => {
                this.loading = false;
              });
          }
        }
      );
    }
  },
  mounted() {
    if (this.id) {
      this.loading = true;
      dispatch.productsGetWhiteList(Number(this.id)).then(data => {
        this.form.setFieldsValue({
          ...data
        });
        this.loading = false;
      });
    }
  }
});
