import { Commit } from "vuex";
import { request, url } from "@/api";
import { RootState, ListQuery } from "@/store";

export interface AMNumber {
  id: number;
  create_time: string;
  update_time: string;
  code: string;
  order_no: string;
  section_id: number;
  name: string;
  email: string;
  number: string;
}

export interface AMNumbersState {
  list: AMNumber[];
  query: ListQuery;
  count: number;
}

const state: AMNumbersState = {
  list: [],
  query: {
    ordering: "id",
    page_number: 1,
    page_size: 10
  },
  count: 0
};

interface Context {
  commit: Commit;
  state: AMNumbersState;
  rootState: RootState;
}

const actions = {
  async getList({ commit }: Context, query: ListQuery) {
    const res: any = await request({
      url: url.number,
      params: query
    });
    commit("SET_LIST", res.results);
    commit("SET_QUERY", {
      ...query,
      page_number: res.page_number,
      page_size: res.page_size
    });
    commit("SET_COUNT", res.count);
    return res;
  },
  async getSectionList() {
    const res: any = await request({
      url: url.numberSection,
      params: {
        page_number: 100
      }
    });
    return res;
  },
  post(context: Context, { orderNos, id }: { orderNos: string[]; id: number }) {
    return request({
      method: "post",
      url: url.number,
      data: {
        order_nos: orderNos,
        section_id: id
      }
    });
  },
  export() {
    return request({
      url: url.number,
      params: {
        ordering: "id",
        page_number: 1,
        page_size: 1000
      }
    });
  }
};

const mutations = {
  SET_LIST: (state: AMNumbersState, list: AMNumber[]) => {
    state.list = list;
  },
  SET_QUERY: (state: AMNumbersState, query: ListQuery) => {
    state.query = query;
  },
  SET_COUNT: (state: AMNumbersState, count: number) => {
    state.count = count;
  }
};

export default {
  namespaced: true,
  state,
  actions,
  mutations
};
