import { Commit } from "vuex";
import { request, url, isTest } from "@/api";
import { RootState, ListQuery } from "@/store/modules";

export interface SettingsState {
  shipping: {
    amount: number;
    is_open: boolean;
    threshold: number;
  };
  refund: {
    is_open: boolean;
  };
  address: {
    edit_addr: boolean;
  };
}

const state: SettingsState = {
  shipping: {
    amount: 0,
    is_open: true,
    threshold: 0
  },
  refund: {
    is_open: true
  },
  address: {
    edit_addr: false
  }
};

export interface Media {
  name: string;
  type: string;
  url: string;
}

export interface WWWConfig {
  id: number;
  name: string;
  cn_status: string;
  cn_url: string;
  cn_is_home: boolean;
  cn_is_top: boolean;
  cn_priority: number;
  overseas_status: string;
  overseas_url: string;
  overseas_is_home: boolean;
  overseas_is_top: boolean;
  overseas_priority: number;
  top_name: boolean;
  priority: number;
  create_time: string;
  update_time: string;
  code: string;
}

interface Context {
  commit: Commit;
  rootState: RootState;
}

const actions = {
  async get({ commit }: Context) {
    const res: any = await request({
      url: url.settings
    });
    commit("SET_SETTINGS", res);
    return res;
  },
  async post(context: Context, settings: SettingsState) {
    return request({
      url: url.settings,
      method: "post",
      data: settings
    });
  },
  getBlockedList() {
    return request({
      url: url.specialList + "/blocked"
    });
  },
  patchBlockedList(context: Context, ids: number[]) {
    return request({
      method: "post",
      url: url.specialList + "/blocked",
      data: {
        ids
      }
    });
  },
  getDelayList() {
    return request({
      url: url.specialList + "/delay"
    });
  },
  patchDelayList(
    context: Context,
    { duration, ids }: { duration: number; ids: number[] }
  ) {
    return request({
      method: "post",
      url: url.specialList + "/delay",
      data: {
        duration,
        ids
      }
    });
  },
  getWwwConfig() {
    return request({
      url: url.wwwConfig,
      params: {
        t: new Date().getTime()
      }
    });
  },
  async patchWwwConfig(context: Context, list: WWWConfig[]) {
    const file = new File([JSON.stringify(list)], "settings.json", {
      type: "application/json"
    });
    const res: any = await request({
      method: "POST",
      url: url.wwwConfigUpload,
      data: {
        file_type: "json",
        file_prefix: isTest ? "test_config" : "config",
        file_name: "settings"
      }
    });
    const formData = new FormData();
    formData.append("OSSAccessKeyId", res.access_id);
    formData.append("policy", res.policy);
    formData.append("Signature", res.signature);
    formData.append("key", res.path);
    formData.append("file", file);
    formData.append("success_action_status", "200");
    await request({
      method: "POST",
      url: res.host,
      data: formData
    });
    return res.host + "/" + res.path;
  },
  getWwwRequestList(context: Context, query: ListQuery) {
    return request({
      url: url.wwwRequest,
      params: query
    });
  },
  getKeyboardCustomList(context: Context, query: ListQuery) {
    return request({
      url: url.keyboardCustom,
      params: query
    });
  }
};

const mutations = {
  SET_SETTINGS: (state: SettingsState, settings: SettingsState) => {
    state.shipping = settings.shipping;
    state.refund = settings.refund;
    state.address = settings.address;
  }
};

export default {
  namespaced: true,
  state,
  actions,
  mutations
};
