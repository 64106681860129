import Vue from "vue";
import VueRouter, { RouteConfig, RawLocation } from "vue-router";

const publicPath = "";

export declare interface Paths {
  root: string;
  auth: string;
  products: string;
  productsAdd: string;
  productsEdit: string;
  orders: string;
  notifications: string;
  notificationsAdd: string;
  notificationsEdit: string;
  coupon: string;
  couponActivity: string;
  couponSend: string;
  userCoupon: string;
  numbers: string;
  snCode: string;
  warehouseOrder: string;
  settings: string;
  www: string;
  keyboardCustom: string;
  draw: string;
}

export const paths: Paths = {
  root: publicPath + "/",
  auth: publicPath + "/auth",
  products: publicPath + "/products",
  productsAdd: publicPath + "/products/add",
  productsEdit: publicPath + "/products/:id",
  orders: publicPath + "/orders",
  notifications: publicPath + "/notifications",
  notificationsAdd: publicPath + "/notifications/add",
  notificationsEdit: publicPath + "/notifications/:id",
  coupon: publicPath + "/coupon-activity/coupon/:id",
  couponActivity: publicPath + "/coupon-activity",
  couponSend: publicPath + "/coupon-send",
  userCoupon: publicPath + "/user-coupon",
  numbers: publicPath + "/numbers",
  snCode: publicPath + "/sn-code",
  warehouseOrder: publicPath + "/warehouse-order",
  settings: publicPath + "/settings",
  www: publicPath + "/www",
  keyboardCustom: publicPath + "/keyboard-custom",
  draw: publicPath + "/draw"
};

const routes: Array<RouteConfig> = [
  {
    path: paths.auth,
    component: () => import("@/layouts/AuthLayout.vue"),
    children: []
  },
  {
    path: paths.root,
    redirect: paths.products,
    component: () => import("@/layouts/MainLayout.vue"),
    children: [
      {
        path: paths.products,
        name: "商品列表",
        component: () => import("@/views/ProductList.vue")
      },
      {
        path: paths.productsAdd,
        name: "添加商品",
        component: () => import("@/views/ProductDetail.vue")
      },
      {
        path: paths.productsEdit,
        name: "修改商品",
        component: () => import("@/views/ProductDetail.vue")
      },
      {
        path: paths.orders,
        name: "订单列表",
        component: () => import("@/views/OrderList.vue")
      },
      {
        path: paths.notifications,
        name: "消息列表",
        component: () => import("@/views/NotificationList.vue")
      },
      {
        path: paths.notificationsAdd,
        name: "添加消息",
        component: () => import("@/views/NotificationDetail.vue")
      },
      {
        path: paths.notificationsEdit,
        name: "修改消息",
        component: () => import("@/views/NotificationDetail.vue")
      },
      {
        path: paths.couponActivity,
        name: "券码活动列表",
        component: () => import("@/views/CouponActivityList.vue")
      },
      {
        path: paths.coupon,
        name: "券码列表",
        component: () => import("@/views/CouponList.vue")
      },
      {
        path: paths.couponSend,
        name: "券码批量发送列表",
        component: () => import("@/views/CouponSendList.vue")
      },
      {
        path: paths.userCoupon,
        name: "用户券码",
        component: () => import("@/views/UserCouponList.vue")
      },
      {
        path: paths.numbers,
        name: "选号列表",
        component: () => import("@/views/NumberList.vue")
      },
      {
        path: paths.snCode,
        name: "SN 码列表",
        component: () => import("@/views/SNList.vue")
      },
      {
        path: paths.warehouseOrder,
        name: "订单仓库",
        component: () => import("@/views/WarehouseOrder.vue")
      },
      {
        path: paths.settings,
        name: "商城设置",
        component: () => import("@/views/Settings.vue")
      },
      {
        path: paths.www,
        name: "官网设置",
        component: () => import("@/views/WWWConfig.vue")
      },
      {
        path: paths.keyboardCustom,
        name: "65 Less 定制",
        component: () => import("@/views/KeyboardCustomList.vue")
      },
      {
        path: paths.draw,
        name: "官网抽奖",
        component: () => import("@/views/DrawList.vue")
      }
    ]
  },
  {
    path: "*",
    redirect: paths.root
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  scrollBehavior: (to, from, savedPosition) => {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  },
  routes
});

const VueRouterPush = VueRouter.prototype.push;
const VueRouterReplace = VueRouter.prototype.replace;

VueRouter.prototype.push = function push(location: RawLocation) {
  return (VueRouterPush.call(this, location) as any).catch((err: Error) => err);
};
VueRouter.prototype.replace = function replace(location: RawLocation) {
  return (VueRouterReplace.call(this, location) as any).catch(
    (err: Error) => err
  );
};

Vue.use(VueRouter);
Vue.prototype.$paths = paths;

export default router;
