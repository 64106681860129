












import Vue from "vue";

export default Vue.extend({
  name: "VUserCouponCode",
  props: {
    value: Array
  },
  computed: {
    formatValue(): string[] {
      const value: string[] = [];
      this.value?.map((item: any) => {
        value.push(item.user_id + " : " + item.coupon_activity_code);
      });
      return value;
    }
  }
});
