












import Vue, { PropType } from "vue";
import { dispatch, Order } from "@/store";

export default Vue.extend({
  name: "VOrderRefund",
  props: {
    order: Object as PropType<Order>
  },
  data() {
    return {};
  },
  computed: {
    refundVisible(): boolean {
      const { order, shipping_info } = this.order;
      if (
        order.status === "paid" &&
        (!shipping_info || shipping_info.status === "paid")
      ) {
        const paidSubOrders = this.order.sub_orders.filter(
          (subOrder: any) => subOrder.status === "paid"
        );
        return this.order.sub_orders.length === paidSubOrders.length;
      } else {
        return false;
      }
    },
    refundingVisible(): boolean {
      const { order, shipping_info, sub_orders } = this.order;
      if (
        order.status === "paid" &&
        (!shipping_info || shipping_info.status === "refunding")
      ) {
        const refundingSubOrders = sub_orders.filter(
          (subOrder: any) => subOrder.status === "refunding"
        );
        return sub_orders.length === refundingSubOrders.length;
      } else {
        return false;
      }
    }
  },
  methods: {
    onRefund() {
      this.$confirm({
        title: "确认对此平台订单申请退款？",
        content: "申请退款后，此平台订单下的所有商品订单都将申请退款。",
        onOk: () => {
          dispatch
            .ordersStatusPatch({
              ...this.order,
              order: {
                ...this.order.order,
                status: "refunding"
              }
            })
            .then(() => {
              this.$message.success("申请退款成功");
              this.$emit("change");
            });
        }
      });
    },
    onRefundConfirm() {
      this.$confirm({
        title: "确认退款？",
        content: "确认退款后，此平台订单下的所有商品订单都将确认退款。",
        onOk: () => {
          dispatch
            .ordersStatusPatch({
              ...this.order,
              order: {
                ...this.order.order,
                status: "refunded"
              }
            })
            .then(() => {
              this.$message.success("退款已确认");
              this.$emit("change");
            });
        }
      });
    },
    onRefundCancel() {
      this.$confirm({
        title: "拒绝退款？",
        content: "拒绝退款后，此平台订单下的所有商品订单都将拒绝退款。",
        onOk: () => {
          dispatch
            .ordersStatusPatch({
              ...this.order,
              order: {
                ...this.order.order,
                status: "paid"
              }
            })
            .then(() => {
              this.$message.success("退款已拒绝");
              this.$emit("change");
            });
        }
      });
    }
  }
});
