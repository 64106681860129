var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.subOrder.status === 'paid' || _vm.subOrder.status === 'shipping')?_c('span',[(_vm.subOrder.status === 'paid')?_c('a',{on:{"click":_vm.onAddExpress}},[_vm._v("发货")]):_c('span',[_c('a',{on:{"click":_vm.onEditExpress}},[_vm._v(" 修改快递信息 ")]),_c('a-divider',{attrs:{"type":"vertical"}}),_c('a',{on:{"click":_vm.onInterceptExpress}},[_vm._v(" 快递拦截 ")])],1),_c('a-modal',{attrs:{"title":"快递信息","visible":_vm.visible,"confirm-loading":_vm.lodaing},on:{"ok":_vm.onOk,"cancel":function($event){_vm.visible = false}}},[_c('a-form',{staticClass:"form",attrs:{"form":_vm.form}},[_c('a-form-item',{directives:[{name:"show",rawName:"v-show",value:(false),expression:"false"}]},[_c('div',{directives:[{name:"decorator",rawName:"v-decorator",value:(['id']),expression:"['id']"}]}),_c('div',{directives:[{name:"decorator",rawName:"v-decorator",value:(['sub_order_no', {}]),expression:"['sub_order_no', {}]"}]})]),_c('a-form-item',{attrs:{"label":"快递公司"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'supplier',
            {
              rules: [{ required: true, message: '请选择快递公司！' }]
            }
          ]),expression:"[\n            'supplier',\n            {\n              rules: [{ required: true, message: '请选择快递公司！' }]\n            }\n          ]"}]},_vm._l((_vm.expressSupplier),function(item){return _c('a-select-option',{key:item.code,attrs:{"value":item.code}},[_vm._v(" "+_vm._s(item.name)+" ")])}),1)],1),_c('a-form-item',{attrs:{"label":"快递单号"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'express_no',
            {
              rules: [{ required: true, message: '请输入快递单号！' }]
            }
          ]),expression:"[\n            'express_no',\n            {\n              rules: [{ required: true, message: '请输入快递单号！' }]\n            }\n          ]"}],attrs:{"placeholder":"请输入快递单号"}})],1)],1)],1),_c('a-divider',{attrs:{"type":"vertical"}})],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }