import { Commit } from "vuex";
import { request, url } from "@/api";
import { RootState, ListQuery } from "@/store";

export interface SNProducer {
  name_cn: string;
  name_en: string;
  id?: number;
  create_time?: string;
  update_time?: string;
}

export interface SNProduct {
  name_cn: string;
  name_en: string;
  id?: number;
  create_time?: string;
  update_time?: string;
}

export interface SNCodeGenerator {
  am_number: number;
  year: number;
  season: string;
  batch_number: number;
  start_number: number;
  total_number: number;
  calc_times: number;
  calc_number: number;
}

export interface SNCode {
  sn_code: string;
  date_of_manufacture: string;
  producer_id: number;
  product_id: number;
  id?: number;
  producer_info?: SNProducer;
  product_info?: SNProduct;
  security_code?: string;
  create_time?: string;
  update_time?: string;
}

export interface SNCodesState {
  list: SNCode[];
  query: ListQuery;
  count: number;
}

const state: SNCodesState = {
  list: [],
  query: {
    ordering: "-id",
    page_number: 1,
    page_size: 10
  },
  count: 0
};

interface Context {
  commit: Commit;
  state: SNCodesState;
  rootState: RootState;
}

const actions = {
  async getList({ commit }: Context, query: ListQuery) {
    const res: any = await request({
      url: url.SNCode,
      params: query
    });
    commit("SET_LIST", res.results);
    commit("SET_QUERY", {
      ...query,
      page_number: res.page_number,
      page_size: res.page_size
    });
    commit("SET_COUNT", res.count);
    return res;
  },
  async generator(context: Context, data: SNCodeGenerator) {
    return request({
      method: "post",
      url: url.SNCodeGenerator,
      data
    });
  },
  async import(context: Context, SNCodes: SNCode[]) {
    return request({
      method: "post",
      url: url.SNCodeImport,
      data: SNCodes
    });
  },
  async getProducerList() {
    const res: any = await request({
      url: url.SNProducer,
      params: {
        page_size: 100
      }
    });
    return res;
  },
  postProducer(context: Context, producer: SNProducer) {
    return request({
      method: "post",
      url: url.SNProducer,
      data: producer
    });
  },
  patchProducer(context: Context, producer: SNProducer) {
    return request({
      method: "patch",
      url: url.SNProducer + "/" + producer.id,
      data: producer
    });
  },
  async getProductList() {
    const res: any = await request({
      url: url.SNProduct,
      params: {
        page_size: 100
      }
    });
    return res;
  },
  postProduct(context: Context, product: SNProduct) {
    return request({
      method: "post",
      url: url.SNProduct,
      data: product
    });
  },
  patchProduct(context: Context, product: SNProduct) {
    return request({
      method: "patch",
      url: url.SNProduct + "/" + product.id,
      data: product
    });
  }
};

const mutations = {
  SET_LIST: (state: SNCodesState, list: SNCode[]) => {
    state.list = list;
  },
  SET_QUERY: (state: SNCodesState, query: ListQuery) => {
    state.query = query;
  },
  SET_COUNT: (state: SNCodesState, count: number) => {
    state.count = count;
  }
};

export default {
  namespaced: true,
  state,
  actions,
  mutations
};
