







































































































































import Vue, { PropType } from "vue";
import { dispatch, Sku, Media } from "@/store";
import { download, xlsx } from "@/utils";

export default Vue.extend({
  name: "VSkuList",
  props: {
    value: {
      type: Array as PropType<Sku[]>,
      default: function() {
        return [];
      }
    },
    productId: Number,
    productName: String,
    isVirtual: Boolean,
    isCoinPay: Boolean
  },
  data() {
    return {
      saleList: [] as any[],
      bookedTotal: 0,
      saleTotal: 0,
      cartList: [] as any[],
      userTotal: 0,
      cartTotal: 0,
      timer: 0,
      couponTimer: 0
    };
  },
  computed: {
    columns(): any[] {
      return [
        {
          title: "SKU 图片",
          dataIndex: "medias",
          scopedSlots: { customRender: "medias" }
        },
        {
          title: "名称",
          dataIndex: "name",
          scopedSlots: { customRender: "name" }
        },
        {
          title: "库存",
          dataIndex: "stock",
          scopedSlots: { customRender: "stock" },
          width: "85px"
        },
        this.isCoinPay
          ? {
              title: "MIAOCOIN",
              dataIndex: "coin",
              scopedSlots: { customRender: "coin" },
              width: "115px"
            }
          : {
              title: "价格(元)",
              dataIndex: "amount",
              scopedSlots: { customRender: "amount" },
              width: "115px"
            },
        {
          title: "交易上限",
          dataIndex: "limit",
          scopedSlots: { customRender: "limit" },
          width: "75px"
        },
        // {
        //   title: "邮费(元)",
        //   dataIndex: "extra_amount",
        //   scopedSlots: { customRender: "extra_amount" }
        // },
        {
          title: "排序权重",
          dataIndex: "priority",
          scopedSlots: { customRender: "priority" },
          width: "75px"
        },
        this.isVirtual
          ? {
              title: "优惠券 ID",
              dataIndex: "coupon_id",
              scopedSlots: { customRender: "coupon_id" }
            }
          : {
              title: "商品名称",
              dataIndex: "goods_name",
              scopedSlots: { customRender: "goods_name" }
            },
        this.isVirtual
          ? {}
          : {
              title: "商品编号",
              dataIndex: "goods_code",
              scopedSlots: { customRender: "goods_code" }
            },
        {
          key: "user",
          dataIndex: "id",
          slots: { title: "user" },
          customRender: (text: string) => {
            const sale = this.cartList.find(item => item.sku_id === text);
            return sale ? sale.count : "-";
          },
          align: "center",
          width: "75px"
        },
        {
          key: "cart",
          dataIndex: "id",
          slots: { title: "cart" },
          customRender: (text: string) => {
            const sale = this.cartList.find(item => item.sku_id === text);
            return sale ? sale.sum : "-";
          },
          align: "center",
          width: "65px"
        },
        {
          key: "sum",
          dataIndex: "id",
          slots: { title: "sum" },
          customRender: (text: string) => {
            const sale = this.saleList.find(item => item.sku_id === text);
            return sale ? sale.sub_order_info.sum : "-";
          },
          align: "center",
          width: "65px"
        },
        {
          key: "booked",
          dataIndex: "id",
          slots: { title: "booked" },
          customRender: (text: string) => {
            const sale = this.saleList.find(item => item.sku_id === text);
            return sale ? sale.booked_info.booked : "-";
          },
          align: "center",
          width: "65px"
        },
        {
          title: "ID",
          dataIndex: "id",
          align: "center",
          width: "50px"
        },
        {
          title: "操作",
          dataIndex: "actions",
          scopedSlots: { customRender: "actions" },
          align: "center",
          width: "50px"
        }
      ];
    }
  },
  methods: {
    onMediasChange(index: number, medias: Media[]) {
      this.value[index].medias = medias;
      this.onChange(this.value);
    },
    onNameChange(index: number, name: string) {
      if (this.value.find(item => item.name === name)) {
        this.$message.warning("SKU 名不能重复！");
        return;
      }
      this.value[index].name = name;
      this.onChange(this.value);
    },
    onNumberChange(index: number, key: string, value: number) {
      (this.value[index] as any)[key] = value;
      this.onChange(this.value);
    },
    onValueChange(index: number, key: string, value: string) {
      (this.value[index] as any)[key] = value;
      this.onChange(this.value);
    },
    onCouponIdChange(index: number, value: string) {
      (this.value[index] as any).coupon_id =
        parseInt(value.replace(/[^0-9]/g, "")) || "";
      this.onChange(this.value);
    },
    onCouponIdSync(index: number) {
      console.log("onCouponIdSync");
      const id = (this.value[index] as any).coupon_id;
      if (!id) return;
      dispatch
        .couponsGetCouponStock({
          product_id: this.productId,
          coupon_id: (this.value[index] as any).coupon_id
        })
        .then(res => {
          console.log(this.value);
          (this.value[index] as any).stock = parseInt(res.total);
          this.onChange(this.value);
          this.$message.success("优惠券库存已同步！");
        });
    },
    onDeleteLimit(index: number) {
      const sku: Sku = this.value[index];
      this.$confirm({
        title: `确认清除 SKU 【${sku.name}】 的历史购买限制？`,
        onOk: () => {
          dispatch.productsSaleLimitDelete(sku.id as number).then(() => {
            this.$message.success("清除成功！");
          });
        }
      });
    },
    onDelete(index: number) {
      const sku: Sku = this.value[index];
      this.$confirm({
        title: `确认删除 SKU 【${sku.name}】？`,
        content:
          "用户购物车已加购的 SKU 也会被删除，用户已下单的 SKU 订单不会受影响。",
        onOk: () => {
          this.value.splice(index, 1);
          this.onChange(this.value);
        }
      });
    },
    onChange(value: Sku[]) {
      this.$emit("change", [...value]);
    },
    getSale() {
      const skuList = this.value.filter(item => item.id);
      const skuIds = Array.from(skuList, sku => Number(sku.id));
      dispatch.productsSale(skuIds).then(res => {
        let bookedTotal = 0;
        let saleTotal = 0;
        res.results.map((item: any) => {
          bookedTotal += item.booked_info.booked;
          saleTotal += item.sub_order_info.sum;
        });
        this.bookedTotal = bookedTotal;
        this.saleTotal = saleTotal;
        this.saleList = res.results;
      });
      dispatch.productsCartCount(skuIds).then(res => {
        let userTotal = 0;
        let cartTotal = 0;
        res.results.map((item: any) => {
          userTotal += item.count;
          cartTotal += item.sum;
        });
        this.userTotal = userTotal;
        this.cartTotal = cartTotal;
        this.cartList = res.results;
      });
    },
    exportSale() {
      const data = [
        ["ID", "产品", "SKU ID", "SKU", "加购人数", "加购数", "销量"]
      ];
      let userTotal = 0 as any;
      let cartTotal = 0 as any;
      let saleTotal = 0 as any;
      this.value.map((sku, index) => {
        const cart = this.cartList.find(item => sku.id === item.sku_id);
        const sale = this.saleList.find(item => sku.id === item.sku_id);
        const row = [
          index === 0 ? this.productId : "",
          index === 0 ? this.productName : "",
          sku ? sku.id : "",
          sku ? sku.name : "",
          cart.count,
          cart.sum,
          sale.sub_order_info.sum
        ];
        userTotal += cart.count;
        cartTotal += cart.sum;
        saleTotal += sale.sub_order_info.sum;
        data.push(row);
        data.push(["", "", "", "", "", "", ""]);
      });
      data.push(["", "", "", "合计", userTotal, cartTotal, saleTotal]);
      const file = xlsx([
        {
          name: "加购销量数据统计",
          data,
          cols: [
            { wch: 5 },
            { wch: 60 },
            { wch: 8 },
            { wch: 30 },
            { wch: 8 },
            { wch: 8 },
            { wch: 8 }
          ]
        }
      ]);
      download(file, this.productName + "加购销量数据统计.xlsx");
    }
  },
  mounted() {
    clearInterval(this.timer);
    this.timer = setInterval(this.getSale, 3000);
  },
  beforeDestroy() {
    clearInterval(this.timer);
  }
});
