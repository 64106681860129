import { Commit } from "vuex";
import { request, url } from "@/api";
import { RootState, ListQuery } from "@/store";

export interface Notification {
  content: string;
  create_time: string;
  is_active: boolean;
  publish_at: string;
  title: string;
  type: "public" | "private";
  update_time: string;
  user_id: number;
  user_ids: number[];
  id?: number;
}

export interface NotificationsState {
  list: Notification[];
  query: ListQuery;
  count: number;
}

const state: NotificationsState = {
  list: [],
  query: {
    ordering: "-publish_at",
    user_ids__is_null: false,
    page_number: 1,
    page_size: 10
  },
  count: 0
};

interface Context {
  commit: Commit;
  state: NotificationsState;
  rootState: RootState;
}

const actions = {
  async getList({ commit }: Context, query: ListQuery) {
    const res: any = await request({
      url: url.notification,
      params: query
    });
    commit("SET_LIST", res.results);
    commit("SET_QUERY", {
      ...query,
      page_number: res.page_number,
      page_size: res.page_size
    });
    commit("SET_COUNT", res.count);
    return res;
  },
  get(context: Context, id: number) {
    return request({
      url: url.notification + "/" + id
    });
  },
  post(context: Context, notification: Notification) {
    return request({
      method: "post",
      url: url.notification,
      data: notification
    });
  },
  patch(context: Context, notification: Notification) {
    return request({
      method: "patch",
      url: url.notification + "/" + notification.id,
      data: notification
    });
  },
  delete(context: Context, notification: Notification) {
    return request({
      method: "delete",
      url: url.notification + "/" + notification.id
    });
  }
};

const mutations = {
  SET_LIST: (state: NotificationsState, list: Notification[]) => {
    state.list = list;
  },
  SET_QUERY: (state: NotificationsState, query: ListQuery) => {
    state.query = query;
  },
  SET_COUNT: (state: NotificationsState, count: number) => {
    state.count = count;
  }
};

export default {
  namespaced: true,
  state,
  actions,
  mutations
};
