import { Commit } from "vuex";
import { request, url } from "@/api";
import { RootState, ListQuery } from "@/store";

export interface WarehouseProducer {
  name_cn: string;
  name_en: string;
  id?: number;
  create_time?: string;
  update_time?: string;
}

export interface WarehousePlatform {
  name_cn: string;
  name_en: string;
  id?: number;
  create_time?: string;
  update_time?: string;
}

export interface WarehouseOrder {
  address_city: string;
  address_consignee: string;
  address_country: string;
  address_detail: string;
  address_district: string;
  address_phone: string;
  address_province: string;
  aggregate_amount: number;
  aggregate_refund_amount: number;
  aggregate_refunding_amount: number;
  amount: number;
  comment: string;
  currency: string;
  express_no: string;
  express_supplier: string;
  express_time: string;
  goods_code: string;
  goods_name: string;
  number: number;
  order_no: string;
  payment_method: string;
  payment_time: string;
  platform_id: number;
  producer_id: number;
  purchase_time: string;
  refund_time: string;
  refunded_amount: number;
  refunding_amount: number;
  security_code: string;
  sn_code: string;
  status: string;
  sub_order_no: string;
  total_amount: number;
  transaction_id: string;
  user_id: number;
}

export interface WarehouseState {
  list: WarehouseOrder[];
  query: ListQuery;
  count: number;
}

const state: WarehouseState = {
  list: [],
  query: {
    ordering: "-id",
    page_number: 1,
    page_size: 10
  },
  count: 0
};

interface Context {
  commit: Commit;
  state: WarehouseState;
  rootState: RootState;
}

const actions = {
  async getList({ commit }: Context, query: ListQuery) {
    const res: any = await request({
      url: url.warehouseOrder,
      params: query
    });
    commit("SET_LIST", res.results);
    commit("SET_QUERY", {
      ...query,
      page_number: res.page_number,
      page_size: res.page_size
    });
    commit("SET_COUNT", res.count);
    return res;
  },
  async import(context: Context, orders: WarehouseOrder[]) {
    return request({
      method: "post",
      url: url.warehouseOrderImport,
      data: orders
    });
  },
  async getPlatformList() {
    const res: any = await request({
      url: url.warehousePlatform,
      params: {
        page_size: 100
      }
    });
    return res;
  },
  postPlatform(context: Context, platform: WarehousePlatform) {
    return request({
      method: "post",
      url: url.warehousePlatform,
      data: platform
    });
  },
  patchPlatform(context: Context, platform: WarehousePlatform) {
    return request({
      method: "patch",
      url: url.warehousePlatform + "/" + platform.id,
      data: platform
    });
  },
  async getProducerList() {
    const res: any = await request({
      url: url.warehouseProducer,
      params: {
        page_size: 100
      }
    });
    return res;
  },
  postProducer(context: Context, producer: WarehouseProducer) {
    return request({
      method: "post",
      url: url.warehouseProducer,
      data: producer
    });
  },
  patchProducer(context: Context, producer: WarehouseProducer) {
    return request({
      method: "patch",
      url: url.warehouseProducer + "/" + producer.id,
      data: producer
    });
  }
};

const mutations = {
  SET_LIST: (state: WarehouseState, list: WarehouseOrder[]) => {
    state.list = list;
  },
  SET_QUERY: (state: WarehouseState, query: ListQuery) => {
    state.query = query;
  },
  SET_COUNT: (state: WarehouseState, count: number) => {
    state.count = count;
  }
};

export default {
  namespaced: true,
  state,
  actions,
  mutations
};
