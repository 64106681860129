
















import Vue from "vue";
import XLSX from "xlsx";
import { download, xlsx } from "@/utils";

export default Vue.extend({
  name: "VUploadUserIds",
  methods: {
    onOrderUpload({ file }: { file: File }) {
      const reader = new FileReader();
      reader.onload = (e: any) => {
        const data = new Uint8Array(e.target.result);
        const workbook = XLSX.read(data, { type: "array" });
        const name = workbook.SheetNames[0];
        const sheet = workbook.Sheets[name];
        const ids: string[] = [];
        let columnKey = "";
        for (const key in sheet) {
          const { w } = sheet[key];
          if (columnKey && key.includes(columnKey) && !ids.includes(w)) {
            ids.push(w);
          }
          if (w === "客户ID" || w === "用户ID") {
            columnKey = key.replace("1", "");
          }
        }
        this.$emit("upload", ids);
        this.$message.success("名单导入成功");
      };
      reader.onerror = () => {
        this.$message.error("名单导入失败");
      };
      reader.readAsArrayBuffer(file);
    },
    onDownload() {
      const file = xlsx([
        {
          name: "用户ID导入模板",
          data: [["用户ID"]],
          cols: [{ wch: 10 }]
        }
      ]);
      download(file, "用户ID导入模板.xlsx");
    }
  }
});
