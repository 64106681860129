




















































import Vue, { PropType } from "vue";
import { dispatch, ListQuery, Product } from "@/store";

export default Vue.extend({
  name: "VProducts",
  props: {
    value: Array as PropType<number[]>
  },
  data() {
    return {
      columns: [
        {
          title: "商品",
          dataIndex: "product.main_medias[0].url",
          scopedSlots: { customRender: "image" },
          width: "100px",
          align: "center"
        },
        {
          key: "name",
          dataIndex: "product.name",
          colSpan: 0,
          scopedSlots: { customRender: "info" }
        },
        {
          key: "actions",
          slots: { title: "actionsTitle" },
          colSpan: 2,
          scopedSlots: { customRender: "actions" },
          align: "right"
        }
      ],
      list: [],
      searchTimer: 0,
      searchLoading: false,
      searchOptions: []
    };
  },
  methods: {
    getList() {
      dispatch
        .productsGetList({
          page_number: 1,
          page_size: 1000,
          ordering: "-priority",
          ids: this.value
        })
        .then(res => {
          this.list = res.results;
        });
    },
    onClick(path: string) {
      window.open(path, "_blank");
    },
    onSearch(value: string) {
      const id = Number(value);
      const query: ListQuery = {
        name__contains: "",
        id: "",
        ordering: "-priority",
        page_number: 1,
        page_size: 10
      };
      if (isNaN(id)) {
        query.name__contains = value;
      } else {
        query.id = id.toString();
      }

      const search = () => {
        this.searchLoading = true;
        dispatch
          .productsGetList(query)
          .then((res: any) => {
            this.searchLoading = false;
            this.searchOptions = res.results.map((product: Product) => ({
              value: product.product.id,
              label: `${product.product.name}`
            }));
          })
          .catch(() => (this.searchLoading = false));
      };

      if (this.searchTimer) {
        clearTimeout(this.searchTimer);
      }
      this.searchTimer = setTimeout(search, 300);
      this.searchOptions = [];
    },
    onSelect(id: number) {
      const value = Array.from(new Set(this.value || []));
      if (value.includes(id)) {
        this.$message.warn("此商品已添加，请勿重复添加");
      } else {
        value.push(id);
        this.$emit("change", value);
        this.$message.success("商品添加成功");
        this.searchOptions = [];
      }
    },
    onRemove(id: number) {
      const value = Array.from(new Set(this.value || []));
      const index = value.findIndex(item => item === id);
      value.splice(index, 1);
      this.$emit("change", value);
    }
  },
  watch: {
    value(value) {
      if (value && value.length) {
        this.getList();
      } else {
        this.list = [];
      }
    }
  }
});
